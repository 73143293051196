import '../../../App.css';
import './FooterCopyrights.css';
import {openExternalLink} from "../../../services/AuthService";
import {useNavigate} from "react-router-dom";
import {MEDIA_BASE_URL} from "../../../constants/API";

function FooterCopyrights({copyrights, language}) {
    const navigate = useNavigate();

    function onExternalLinkClickHandler(url) {
        openExternalLink(url);
    }

    function onPathClickHandler(url) {
        navigate(url);
    }

    return (
        <div className='component-data-container footer-copyrights-container'>
            <div className='footer-copyrights-text body-three for-web'>{copyrights[0][`title-${language}`]}</div>
            <div className='body-three footer-copyrights-terms'>
                {copyrights[0].elements.map((e, index) => (
                    <div className='footer-copyrights-terms' key={index}>
                        {e.isExternalLink === false && (
                            <div
                                className='footer-copyright-term body-three'
                                onClick={() => onPathClickHandler(e.path)}
                            >
                                {e[`element-${language}`]}
                            </div>
                        )}

                        {e.isExternalLink === true && (
                            <div
                                className='footer-copyright-term body-three'
                                onClick={() => onExternalLinkClickHandler(`${MEDIA_BASE_URL}${e.path}`)}
                            >
                                {e[`element-${language}`]}
                            </div>
                        )}

                        {index !== copyrights[0].elements.length - 1 && <div className='body-three'>|</div>}
                    </div>
                ))}
            </div>
            <div className='footer-copyrights-text body-three for-mobile'>{copyrights[0][`titleMobile-${language}`]}</div>
        </div>
    );
}

export default FooterCopyrights;