import '../../../../App.css';
import './OffersWhyGivTradeIcon.css';
import {MEDIA_BASE_URL} from "../../../../constants/API";

function OffersWhyGivTradeIcon({icon, language}) {
    return (
        <div className='offers-why-givtrade-icon-container'>
            <img
                className='offers-why-givtrade-icon-image'
                src={`${MEDIA_BASE_URL}${icon.imagePath}`}
                alt='Icon'
            />

            <div className='offers-why-givtrade-icon-title'>
                {icon[`title-${language}`]}
            </div>

            <div className='offers-why-givtrade-icon-subtitle body-two'>
                {icon[`subtitle-${language}`]}
            </div>
        </div>
    );
}

export default OffersWhyGivTradeIcon;