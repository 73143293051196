import './Navbar.css';
import '../../App.css';
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import NavbarDropdown from "./NavbarDropdown/NavbarDropdown";
import BorderButton from "../ui/BorderButton/BorderButton";
import NavbarCredential from "./NavbarCredential/NavbarCredential";
import NavbarQrCode from "./NavbarQrCode/NavbarQrCode";
// import Chat from "../chat/Chat";
import {openExternalLink} from "../../services/AuthService";
import {MEDIA_BASE_URL} from "../../constants/API";
import usaFlag from '../../assets/usa.jpeg';
import saudiFlag from '../../assets/saudi-arabia.jpg';

function Navbar({navbar, language, onLanguageChange}) {
    const navigate = useNavigate();

    const [navbarLinks, setNavbarLinks] = useState([]);
    const [buttonsWithBorders, setButtonsWithBorders] = useState([]);
    const [credentialButtons, setCredentialButtons] = useState([]);
    const [qrCdeButtons, setQrCdeButtons] = useState([]);
    const [dropdownOpen, setdropdownOpen] = useState(false);

    const languages = {
        en: { label: "English", flag: usaFlag },
        ar: { label: "Arabic", flag: saudiFlag }
    };

    useEffect(() => {
        const navbarLinks = navbar.links
            .filter(link => link.isVisible === true)
            .map(link => ({
                ...link,
                dropdownLinks: link.dropdownLinks.filter(l => l.isVisible === true),
            }));

        const buttonsWithBorders =
            navbar.buttons.filter(button => (button.hasWhiteBorder === true || button.hasFilledBorder === true) && button.isVisible === true);

        const credentialButtons =
            navbar.buttons.filter(button => button.hasWhiteBorder === false && button.hasFilledBorder === false && button.isVisible === true && button.qrCodeUrl === null);

        const qrCodeButtons =
            navbar.buttons.filter(button => button.qrCodeUrl !== null && button.isVisible === true);

        setNavbarLinks(navbarLinks);
        setButtonsWithBorders(buttonsWithBorders);
        setCredentialButtons(credentialButtons);
        setQrCdeButtons(qrCodeButtons);
    }, [navbar]);

    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    // const [showChat, setShowChat] = useState(false);

    function onNavbarCredentialHandler(url) {
        openExternalLink(url);
    }

    function onBorderButtonClickHandler(button) {
        navigate(button.path);
    }

    function onBurgerCloseHandler() {
        setIsBurgerOpen(false);
    }

    function onSwitchLanguageClick(language) {
        onLanguageChange(language);
    }

    return (
        <>
            {navbar && navbarLinks.length > 0 && (
                <nav className="navbar">
                    <div
                        className="burger-menu"
                        onClick={() => setIsBurgerOpen(!isBurgerOpen)}
                    >
                        <img className='burger-image' src={`${MEDIA_BASE_URL}/burger_87837a3184.svg`} alt="Menu"/>
                    </div>

                    <Link to="/" className="navbar-logo for-web">
                        <img className="logo-image" src={`${MEDIA_BASE_URL}/logo_361c048a99.svg`} alt="GivTrade Logo"/>
                    </Link>

                    <div className='for-mobile navbar-logo'>
                        <Link to="/">
                            <img className="logo-image" src={`${MEDIA_BASE_URL}/logo_mobile_724b0386db.svg`}
                                 alt="GivTrade Logo"/>
                        </Link>

                        <div className="language-switcher">
                            {/* Selected Language Button */}
                            <div
                                className="language-button"
                                onClick={() => setdropdownOpen(!dropdownOpen)}
                            >
                                <img
                                    src={languages[language].flag}
                                    alt={languages[language].label}
                                    className="language-flag"
                                />
                            </div>

                            {/* Dropdown Menu */}
                            {dropdownOpen && (
                                <ul className={`
                                    language-dropdown
                                    ${language === 'ar' ? 'language-dropdown-ar' : 'language-dropdown-en'}
                                `}>
                                    {Object.entries(languages).map(([langKey, langData]) => (
                                        <li
                                            key={langKey}
                                            onClick={() => {
                                                onSwitchLanguageClick(langKey);
                                                setdropdownOpen(false);
                                            }}
                                            className={language === langKey ? "active" : ""}
                                        >
                                            <img src={langData.flag} alt={langData.label}
                                                 className="language-flag"/>
                                            <span className='language-label'>{langData.label}</span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>

                    {/*<div className='navbar-message-container'>*/}
                    {/*    <video*/}
                    {/*        className='navbar-message-image'*/}
                    {/*        autoPlay muted loop*/}
                    {/*        onClick={() => setShowChat(!showChat)}*/}
                    {/*    >*/}
                    {/*        <source*/}
                    {/*            src={`${MEDIA_BASE_URL}/uploads/message_animated_ae3771ab35.mp4`}*/}
                    {/*            type='video/mp4'*/}
                    {/*        />*/}
                    {/*        Your browser does not support the video tag.*/}
                    {/*    </video>*/}

                    {/*    {showChat && (*/}
                    {/*        <div className='chat-box-container'>*/}
                    {/*            <Chat forMobile={true} />*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}

                    <div className="nav-links-container">
                        <div className={`
                            navbar-links
                            ${isBurgerOpen ? 'active' : ''}
                            ${language === 'ar' ? 'navbar-links-ar' : 'navbar-links-en'}
                        `}>
                            <div className='links'>
                                {navbarLinks.map(((link, index) => (
                                    <NavbarDropdown
                                        key={index}
                                        title={link[`title-${language}`]}
                                        dropdownPath={link.path}
                                        dropdownLinks={link.dropdownLinks}
                                        onCloseBurger={onBurgerCloseHandler}
                                        language={language}
                                    />
                                )))}
                            </div>

                            <div className='credentials-button-container for-mobile'>
                                {buttonsWithBorders.map((button, index) => (
                                    <BorderButton
                                        key={index}
                                        title={button[`title-${language}`]}
                                        onClick={() => onNavbarCredentialHandler(button.externalLink)}
                                        hasWhiteBorder={button.hasWhiteBorder}
                                        hasFilledBorder={button.hasFilledBorder}
                                    />
                                ))}

                                <div className='credentials-container'>
                                    {credentialButtons.map((button, index) => (
                                        <NavbarCredential
                                            key={index}
                                            title={button[`title-${language}`]}
                                            source={`${MEDIA_BASE_URL}${button.imagePath}`}
                                            onClick={() => onNavbarCredentialHandler(button.externalLink)}
                                        />
                                    ))}

                                    {/*{qrCdeButtons.length > 0 && <div className='credentials-separator'></div>}*/}

                                    {/*{qrCdeButtons.map((button, index) => (*/}
                                    {/*    <NavbarQrCode key={index} button={button}/>*/}
                                    {/*))}*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='nav-links-container for-web'>
                        <div className={`navbar-links ${isBurgerOpen ? 'active' : ''}`}>
                            {buttonsWithBorders.map((button, index) => (
                                <BorderButton
                                    key={index}
                                    title={button[`title-${language}`]}
                                    onClick={() => onNavbarCredentialHandler(button.externalLink)}
                                    hasWhiteBorder={button.hasWhiteBorder}
                                    hasFilledBorder={button.hasFilledBorder}
                                />
                            ))}

                            <div className='credentials-container'>
                                {credentialButtons.map((button, index) => (
                                    <NavbarCredential
                                        key={index}
                                        title={button[`title-${language}`]}
                                        source={`${MEDIA_BASE_URL}${button.imagePath}`}
                                        onClick={() => onNavbarCredentialHandler(button.externalLink)}
                                    />
                                ))}

                                {/*{qrCdeButtons.length > 0 && <div className='credentials-separator'></div>}*/}

                                {/*{qrCdeButtons.map((button, index) => (*/}
                                {/*    <NavbarQrCode key={index} button={button}/>*/}
                                {/*))}*/}
                            </div>
                        </div>
                    </div>

                    <div className="language-switcher for-web">
                        {/* Selected Language Button */}
                        <div
                            className="language-button"
                            onClick={() => setdropdownOpen(!dropdownOpen)}
                        >
                            <img src={languages[language].flag} alt={languages[language].label}
                                 className="language-flag"/>
                            {languages[language].label}
                        </div>

                        {/* Dropdown Menu */}
                        {dropdownOpen && (
                            <ul className="language-dropdown language-dropdown-en">
                                {Object.entries(languages).map(([langKey, langData]) => (
                                    <li
                                        key={langKey}
                                        onClick={() => {
                                            onSwitchLanguageClick(langKey);
                                            setdropdownOpen(false);
                                        }}
                                        className={language === langKey ? "active" : ""}
                                    >
                                        <img src={langData.flag} alt={langData.label} className="language-flag"/>
                                        <span className='language-label'>{langData.label}</span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </nav>
            )}
        </>
    );
}

export default Navbar;