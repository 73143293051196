import axios from "axios";
import {BASE_URL} from "../constants/API";

export function getOffersHeader() {
    return axios.get(BASE_URL + '/offers-headers?populate=image');
}

export function getOffersWhyGivTrade() {
    return axios.get(BASE_URL + '/offers-why-givtrades');
}

export function getOffersSteps() {
    return axios.get(BASE_URL + '/offers-steps?populate=separatorImage&populate=separatorMobileImage');
}

export function getOffersBenefits() {
    return axios.get(BASE_URL + '/offers-benefits?populate=image');
}