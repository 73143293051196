import '../../../App.css';
import './OurAppSource.css';
import useVisibility from "../../../hooks/useVisibility";
import OurAppDesktopSource from "./our-app-desktop-source/OurAppDesktopSource";
import OurAppMobileSource from "./our-app-mobile-source/OurAppMobileSource";

function OurAppSource({content, language}) {
    const [isVisible, containerRef] = useVisibility();

    return (
        <>
            {content && (
                <div
                    className={`component-container-no-full-height ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container our-app-source-container'>
                        <h3 className='our-app-source-title'>{content[`title${language}`]}</h3>
                        <div className='our-app-source-description body-one'>
                            {content[`description${language}`]}
                        </div>

                        <div className='our-app-source-sources-container'>
                            <OurAppDesktopSource source={content.desktopSources} />

                            <img className='for-web' src={`${content.separatorImage.url}`} alt='Separator'/>
                            <img
                                className='our-app-source-separator for-mobile'
                                src={`${content.separatorImageMobile.url}`}
                                alt='Separator'
                            />

                            <div className='our-app-source-image-container card'>
                                <img
                                    src={`${content.image.url}`}
                                    alt='Mobile'
                                />
                            </div>

                            <img className='for-web' src={`${content.separatorImage.url}`} alt='Separator' />
                            <img
                                className='our-app-source-separator for-mobile'
                                src={`${content.separatorImageMobile.url}`}
                                alt='Separator'
                            />

                            <OurAppMobileSource source={content.mobileSources} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default OurAppSource;