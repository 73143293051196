import '../../../App.css';
import './HomepageCard.css';
import HomepageCardDetails from "./HomepageCardDetails/HomepageCardDetails";
import {useState} from "react";
import {MEDIA_BASE_URL} from "../../../constants/API";
import useVisibility from "../../../hooks/useVisibility";
import ComponentTitle from "../../ui/ComponentTitle/ComponentTitle";

function HomepageCard({cardDetails, language}) {
    const [isVisible, containerRef] = useVisibility();

    const [index, setIndex] = useState(1);
    const [direction, setDirection] = useState('next');
    const [active, setActive] = useState(true);

    const formattedLanguage = language.charAt(0).toUpperCase() + language.slice(1);

    function onNextClaretHandler( ) {
        setActive(false);
        setDirection('next');

        setTimeout(() => {
            setIndex(index === cardDetails.length ? 1 : index + 1);
            setActive(true);
        }, 1000);
    }

    function onPreviousClaretHandler() {
        setActive(false);
        setDirection('previous');

        setTimeout(() => {
            setIndex(index === 1 ? cardDetails.length : index - 1);
            setActive(true);
        }, 1000);
    }

    return (
        <div className='component-container'>
            <div className='homepage-card-title-container'>
                <ComponentTitle
                    titlePartOne={cardDetails[0][`globalTitlePartOne${formattedLanguage}`]}
                    titlePartTwo={cardDetails[0][`globalTitlePartTwo${formattedLanguage}`]}
                    titlePartOneColor='white'
                />
            </div>

            <div
                className={`homepage-card card box-shadow-all-sides ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                ref={containerRef}
            >
                {cardDetails.length > 0 && (
                    <>
                        <div
                            className={`homepage-card-content
                    ${direction === 'previous' ? 'slide-out-right' : 'slide-out-left'}
                    ${active && direction === 'previous' && 'slide-in-left'}
                    ${active && direction === 'next' && 'slide-in-right'}`}
                        >
                            <div className='homepage-card-image-container homepage-card-image-container-for-mobile'>
                                <img
                                    className={`homepage-card-image slide-${direction}`}
                                    src={cardDetails[index - 1].imageUrl}
                                    alt='Homepage Card'
                                />
                            </div>

                            <div className='homepage-card-details'>
                                <HomepageCardDetails
                                    cardDetails={cardDetails[index - 1]}
                                    cardDetailsIndex={index}
                                    cardDetailsLength={cardDetails.length}
                                    active={active}
                                    language={formattedLanguage}
                                />
                            </div>

                            <div className='homepage-card-image-container homepage-card-image-container-for-web'>
                                <img
                                    className={`homepage-card-image slide-${direction}`}
                                    src={cardDetails[index - 1].imageUrl}
                                    alt='Homepage Card'
                                />
                            </div>
                        </div>

                        {language === 'ar' && (
                            <div className='homepage-card-clarets-container'>
                                <img
                                    className='clarets-hover'
                                    src={`${MEDIA_BASE_URL}/claret_next_fd43c0c39e.svg`}
                                    alt='Next Button'
                                    onClick={onNextClaretHandler}
                                />
                                <img
                                    className='clarets-hover'
                                    src={`${MEDIA_BASE_URL}/claret_previous_9d003a9059.svg`}
                                    alt='Previous Button'
                                    onClick={onPreviousClaretHandler}
                                />
                            </div>
                        )}

                        {language !== 'ar' && (
                            <div className='homepage-card-clarets-container'>
                                <img
                                    className='clarets-hover'
                                    src={`${MEDIA_BASE_URL}/claret_previous_9d003a9059.svg`}
                                    alt='Previous Button'
                                    onClick={onPreviousClaretHandler}
                                />
                                <img
                                    className='clarets-hover'
                                    src={`${MEDIA_BASE_URL}/claret_next_fd43c0c39e.svg`}
                                    alt='Next Button'
                                    onClick={onNextClaretHandler}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default HomepageCard;