import '../../../../App.css';
import './AccountTypeForm.css';
import {useEffect, useState} from "react";
import CustomInput from "../../../ui/custom-input/CustomInput";
import CustomButton from "../../../ui/CustomButton/CustomButton";
import {getAccountsTypesForm} from "../../../../services/AccountsService";
import LoadingSpinner from "../../../ui/loading-spinner/LoadingSpinner";
import {EMAIL_SERVER_BASE_URL} from "../../../../constants/API";
import axios from "axios";
import {useNavigate} from "react-router-dom";

function AccountTypeForm({accountTypeId, onCancelForm, language}) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [type, setType] = useState('');
    const [howDidHearAboutUs, setHowDidHearAboutUs] = useState('');

    const [accountTypesForm, setAccountTypesForm] = useState(null);

    const [typeDropdownPlaceholder, setTypeDropdownPlaceholder] = useState('');
    const [typeDropdownOptions, setTypeDropdownOptions] = useState([]);

    const [howDidHearAboutUsDropdownPlaceholder, setHowDidHearAboutUsDropdownPlaceholder] = useState('');
    const [howDidHearAboutUsDropdownOptions, setHowDidHearAboutUsDropdownOptions] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [errors, setErrors] = useState({
        firstNameError: '',
        lastNameError: '',
        phoneNumberError: '',
        emailError: '',
        passwordError: '',
        confirmPasswordError: '',
        typeError: '',
        howDidHearAboutUsError: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        getAccountsTypesForm().then(response => {
            const data = response.data.data[0];
            setAccountTypesForm(data);

            setTypeDropdownPlaceholder(data.typeDropdownPlaceholder);
            setTypeDropdownOptions(data.typeDropdownOptions);

            setHowDidHearAboutUsDropdownPlaceholder(data.howDidHearAboutUsDropdownPlaceholder);
            setHowDidHearAboutUsDropdownOptions(data[`howDidHearAboutUsDropdownOptions${language}`]);
        });
    }, [language]);

    function onFirstNameChangeHandler(valueObject) {
        setFirstName(valueObject.value);
    }

    function onLastNameChangeHandler(valueObject) {
        setLastName(valueObject.value);
    }

    function onPhoneNumberChangeHandler(valueObject) {
        setPhoneNumber(valueObject.value);
    }

    function onEmailChangeHandler(valueObject) {
        setEmail(valueObject.value);
    }

    function onPasswordChangeHandler(valueObject) {
        setPassword(valueObject.value);
    }

    function onConfirmPasswordChangeHandler(valueObject) {
        setConfirmPassword(valueObject.value);
    }

    function onTypeChangeHandler(valueObject) {
        setType(valueObject.value);
    }

    function onHowDidHearAboutUsChangeHandler(valueObject) {
        setHowDidHearAboutUs(valueObject.value);
    }

    function clearForm() {
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setType('');
        setHowDidHearAboutUs('');
    }

    function onCancelApplicationHandler() {
        onCancelForm();
        clearForm();
    }

    function onSubmitApplicationHandler() {
        setIsLoading(true);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        setErrors({
            firstNameError: '',
            lastNameError: '',
            phoneNumberError: '',
            emailError: '',
            passwordError: '',
            confirmPasswordError: '',
            typeError: '',
            howDidHearAboutUsError: ''
        });

        const firstNameEmpty = firstName == null || firstName === '';
        const lastNameEmpty = lastName == null || lastName === '';
        const phoneNumberEmpty = phoneNumber == null || phoneNumber === '';
        const emailEmpty = email == null || email === '';
        const passwordEmpty = password == null || password === '';
        const confirmPasswordEmpty = confirmPassword == null || confirmPassword === '';
        const typeEmpty = type == null || type === '';
        const howDidHearAboutUsEmpty = howDidHearAboutUs == null || howDidHearAboutUs === '';

        if(firstNameEmpty || lastNameEmpty || phoneNumberEmpty || emailEmpty || passwordEmpty || confirmPasswordEmpty || typeEmpty || howDidHearAboutUsEmpty) {
            setErrors(prevErrors => ({
                ...prevErrors,
                firstNameError: firstNameEmpty && 'Fill all fields',
                lastNameError: lastNameEmpty && 'Fill all fields',
                phoneNumberError: phoneNumberEmpty && 'Fill all fields',
                emailError: emailEmpty && 'Fill all fields',
                passwordError: passwordEmpty && 'Fill all fields',
                confirmPasswordError: confirmPasswordEmpty && 'Fill all fields',
                typeError: typeEmpty && 'Fill all fields',
                howDidHearAboutUsError: howDidHearAboutUsEmpty && 'Fill all fields'
            }));

            return;
        }

        if(!emailEmpty && !emailRegex.test(email)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                emailError: errors.emailError ? 'Enter a valid email address' : errors.emailError
            }));

            return;
        }

        if(!confirmPasswordEmpty && confirmPassword !== password) {
            setErrors(prevErrors => ({
                ...prevErrors,
                confirmPasswordError: 'Passwords do not match'
            }));

            return;
        }

        // Prepare the form data
        const formData = new FormData();
        formData.append('accountTypeId', accountTypeId);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('phoneNumber', phoneNumber);
        formData.append('email', email);
        formData.append('type', type);
        formData.append('howDidHearAboutUs', howDidHearAboutUs);

        // Post to backend
        axios
            .post(`${EMAIL_SERVER_BASE_URL}/select-account-email`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((response) => {
                setIsLoading(false);
                alert('Application sent successfully!');
                clearForm();
                navigate('/');
            })
            .catch((error) => {
                setIsLoading(false);
                alert('Error sending application. Try again later.');
                console.error('Error:', error);
            });
    }

    if(isLoading === true) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <>
            {accountTypesForm && (
                <div className='component-container'>
                    <div className='component-data-container'>
                        <div className='account-type-form-header-container'>
                            <h2 className='account-type-form-header'>{accountTypesForm[`title${language}`]}</h2>
                            <h3 className='account-type-form-subheader'>{accountTypesForm[`subtitle${language}`]}</h3>
                        </div>

                        <div>
                            <div className='account-type-form'>
                                <div className='account-type-form-item'>
                                    <CustomInput
                                        title='First Name'
                                        type='text'
                                        value={firstName}
                                        onChange={onFirstNameChangeHandler}
                                        error={errors.firstNameError}
                                    />
                                </div>

                                <div className='account-type-form-item'>
                                    <CustomInput
                                        title='Last Name'
                                        type='text'
                                        value={lastName}
                                        onChange={onLastNameChangeHandler}
                                        error={errors.lastNameError}
                                    />
                                </div>

                                <div className='account-type-form-item'>
                                    <CustomInput
                                        title='Phone Number'
                                        type='number'
                                        value={phoneNumber}
                                        onChange={onPhoneNumberChangeHandler}
                                        error={errors.phoneNumberError}
                                    />
                                </div>

                                <div className='account-type-form-item'>
                                    <CustomInput
                                        title='Email'
                                        type='text'
                                        value={email}
                                        onChange={onEmailChangeHandler}
                                        error={errors.emailError}
                                    />
                                </div>

                                <div className='account-type-form-item'>
                                    <CustomInput
                                        title='Password'
                                        type='password'
                                        value={password}
                                        onChange={onPasswordChangeHandler}
                                        error={errors.passwordError}
                                    />
                                </div>

                                <div className='account-type-form-item'>
                                    <CustomInput
                                        title='Confirm Password'
                                        type='password'
                                        value={confirmPassword}
                                        onChange={onConfirmPasswordChangeHandler}
                                        error={errors.confirmPasswordError}
                                    />
                                </div>

                                <div className='account-type-form-item'>
                                    <CustomInput
                                        title='Select Type'
                                        value={type}
                                        onChange={onTypeChangeHandler}
                                        error={errors.typeError}
                                        isDropdown={true}
                                        dropdownPlaceholder={typeDropdownPlaceholder}
                                        dropdownOptions={typeDropdownOptions}
                                    />
                                </div>

                                <div className='account-type-form-item'>
                                    <CustomInput
                                        title='How did you hear about us'
                                        value={howDidHearAboutUs}
                                        onChange={onHowDidHearAboutUsChangeHandler}
                                        error={errors.howDidHearAboutUsError}
                                        isDropdown={true}
                                        dropdownPlaceholder={howDidHearAboutUsDropdownPlaceholder}
                                        dropdownOptions={howDidHearAboutUsDropdownOptions}
                                    />
                                </div>
                            </div>

                            <div className='account-type-form-button-container'>
                                <CustomButton title={accountTypesForm[`submitButtonTitle${language}`]} onClick={onSubmitApplicationHandler} />
                                <CustomButton
                                    title={accountTypesForm[`cancelButton${language}`]}
                                    onClick={onCancelApplicationHandler}
                                    backgroundColor='var(--color-black)'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default AccountTypeForm;