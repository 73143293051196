import './Service.css';

function Service({service, pointerImageUrl}) {
    return (
        <div className='accounts-service-data'>
            <img className='accounts-service-icon' src={pointerImageUrl} alt='Correct Sign' />
            <div className='body-one accounts-services-text'>{service}</div>
        </div>
    );
}

export default Service;