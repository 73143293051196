import '../../../App.css';
import './FooterSocialMedia.css';
import {openExternalLink} from "../../../services/AuthService";

function FooterSocialMedia({logos, language}) {
    function isLinkedInLogo(path) {
        return path.includes('linkedin');
    }

    function onSocialMediaClickHandler(url) {
        openExternalLink(url);
    }

    return (
        <div className='footer-data-social-media'>
            <div className='body-three'>Follow Us On</div>
            {logos.map((socialMediaImage, index) => (
                <div
                    className='footer-social-media-image'
                    key={index}
                    onClick={() => onSocialMediaClickHandler(socialMediaImage.url)}
                >
                    <img
                        className={`${isLinkedInLogo(socialMediaImage.logo) && 'footer-social-media-image-linkedin'}`}
                        src={socialMediaImage.logo}
                        alt='Social Media Logo'
                    />
                </div>
            ))}
        </div>
    );
}

export default FooterSocialMedia;