import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import claret from '../../../assets/dropdown-claret.svg';
import '../../../App.css';
import './NavbarDropdown.css';

function NavbarDropdown({ title, dropdownLinks, dropdownPath, onCloseBurger, language }) {
    const navigate = useNavigate();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    function onDropdownClickHandler() {
        navigate(dropdownPath);
        onCloseBurger();
    }

    return (
        <div
            className="navbar-item dropdown"
            onMouseEnter={ () => dropdownLinks && setIsDropdownOpen(true) }
            onMouseLeave={ () => dropdownLinks && setIsDropdownOpen(false) }
            onClick={ () => { dropdownLinks.length > 0 ? setIsDropdownOpen(!isDropdownOpen) : onDropdownClickHandler() }}
        >
            <div className="menu dropdown-button">
                {title}

                {/*{dropdownLinks.length > 0 && (*/}
                {/*    <span className="dropdown-claret">*/}
                {/*        <img src={claret} alt='Dropdown Arrow'/>*/}
                {/*    </span>*/}
                {/*)}*/}
            </div>

            {dropdownLinks && isDropdownOpen && (
                <div
                    className="dropdown-menu box-shadow"
                    onMouseEnter={() => setIsDropdownOpen(true)}
                    onMouseLeave={() => setIsDropdownOpen(false)}
                >
                    {dropdownLinks.map((link, index) => {
                        return (
                            <Link
                                key={index}
                                to={link.path}
                                className="dropdown-link"
                                onClick={onCloseBurger}
                            >
                                {link[`label-${language}`]}
                            </Link>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default NavbarDropdown;