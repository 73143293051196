import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Google Tag Manager
(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-P9KC3MCH'); // Replace with your GTM ID

// Facebook Pixel
(function () {
    if (!window.fbq) {
        window.fbq = function () {
            window.fbq.callMethod ? window.fbq.callMethod.apply(window.fbq, arguments) : window.fbq.queue.push(arguments);
        };
        window.fbq.queue = [];
        window.fbq.version = '2.0';
        window.fbq.loaded = true;
        let fbe = document.createElement('script');
        fbe.async = true;
        fbe.src = 'https://connect.facebook.net/en_US/fbevents.js';
        let s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(fbe, s);
    }
})();

window.fbq('init', '1490613711649944'); // Replace with your Meta Pixel ID
window.fbq('track', 'PageView');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
