import '../../../../App.css';
import './HomepageCardDetails.css';
import CustomButton from "../../../ui/CustomButton/CustomButton";

function HomepageCardDetails({ cardDetails, cardDetailsIndex, cardDetailsLength, language }) {
    const formattedIndex = cardDetailsIndex <= 9 ? '0' + cardDetailsIndex : cardDetailsIndex;
    const formattedLength = cardDetailsLength <= 9 ? '0' + cardDetailsLength : cardDetailsLength;

    return (
        <div className='homepage-card-details-data'>
            {/*<div className='homepage-card-index homepage-card-index-for-web'>{formattedIndex}/{formattedLength}</div>*/}
            <div className='homepage-card-title'>{cardDetails[`title${language}`]}</div>

            <div className='homepage-card-description body-two'>{cardDetails[`description${language}`]}</div>
            <div className='homepage-card-button-container'>
                <CustomButton title={cardDetails[`buttonTitle${language}`]} onClick={cardDetails.onLearnMoreClick} />
                {/*<div className='homepage-card-index homepage-card-index-for-mobile'>{formattedIndex}/{formattedLength}</div>*/}
            </div>
        </div>
    );
}

export default HomepageCardDetails;