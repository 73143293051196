import {useEffect, useState} from "react";
import {
    getAffiliateProgramHeader,
    getAffiliateProgramIntro,
    getAffiliateProgramJoin, getAffiliateProgramSteps
} from "../../services/AffiliateProgramService";
import Header from "../../components/ui/header/Header";
import AffiliateProgramIntro from "../../components/affiliate-program/affiliate-program-intro/AffiliateProgramIntro";
import AffiliateProgramJoin from "../../components/affiliate-program/affiliate-program-join/AffiliateProgramJoin";
import AffiliateProgramSteps from "../../components/affiliate-program/affiliate-program-steps/AffiliateProgramSteps";
import LoadingSpinner from "../../components/ui/loading-spinner/LoadingSpinner";

function AffiliateProgram({language}) {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        async function fetchData() {
            try {
                const [
                    header,
                    intro,
                    join,
                    steps
                ] = await Promise.all([
                    getAffiliateProgramHeader(),
                    getAffiliateProgramIntro(),
                    getAffiliateProgramJoin(),
                    getAffiliateProgramSteps()
                ]);

                const headerData = header.data.data[0];
                const introData = intro.data.data[0];
                const joinData = join.data.data[0];
                const stepsData = steps.data.data[0];

                setContent({
                    header: headerData,
                    intro: introData,
                    join: joinData,
                    steps: stepsData
                });

                setLoading(false);
            } catch (error) {}
        }

        fetchData();
    }, []);

    if(loading === true) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <>
            {content.header && (
                <div>
                    <Header
                        title={content.header[`title${formattedLanguage}`]}
                        subtitle={content.header[`subtitle${formattedLanguage}`]}
                        sourcePath={`${content.header.image.url}`}
                    />

                    <AffiliateProgramIntro content={content.intro} language={language} />

                    <AffiliateProgramJoin content={content.join} language={formattedLanguage} />

                    <AffiliateProgramSteps content={content.steps} language={language} />
                </div>
            )}
        </>
    );
}

export default AffiliateProgram;