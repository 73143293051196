import '../../../App.css';
import './AffiliateProgramSteps.css';
import {MEDIA_BASE_URL} from "../../../constants/API";
import useVisibility from "../../../hooks/useVisibility";
import CustomButton from "../../ui/CustomButton/CustomButton";
import {openExternalLink} from "../../../services/AuthService";

function AffiliateProgramSteps({content, language}) {
    const [isVisible, containerRef] = useVisibility();

    const formattedLanguage = language.toUpperCase();

    function onJoinTodayHandler() {
        openExternalLink(content.buttonLink);
    }

    return (
        <>
            {content && (
                <div
                    className={`component-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='affiliate-program-steps-data-container component-data-container'>
                        {content.content.map((c, index) => (
                            <div key={index} className='affiliate-program-step-container'>
                                <img
                                    className='affiliate-program-step-image card'
                                    src={`${MEDIA_BASE_URL}${c.imagePath}`}
                                    alt={c[`title-${language}`]}
                                />

                                <div className='affiliate-program-step-number-container'>
                                    <div className='affiliate-program-step-number'>{index + 1}</div>

                                    {/*<div className='affiliate-program-step-number-dotted-line'></div>*/}
                                </div>

                                <div className='affiliate-program-step-title'>
                                    {c[`title-${language}`]}
                                </div>

                                <div className='affiliate-program-step-description subtitle'>
                                    {c[`description-${language}`]}
                                </div>
                            </div>
                        ))}

                        {content[`buttonTitle-${formattedLanguage}`] && (
                            <div className='affiliate-steps-button-container'>
                                <CustomButton
                                    title={content[`buttonTitle-${formattedLanguage}`]}
                                    onClick={onJoinTodayHandler}
                                    hasNoGradient={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default AffiliateProgramSteps;