import axios from "axios";
import {BASE_URL} from "../../constants/API";

export async function getStocksHeader() {
    return await axios.get(BASE_URL + '/markets-stocks-headers?populate=image');
}

export async function getStocksDefinition() {
    return await axios.get(BASE_URL + '/markets-stocks-definitions');
}

export async function getStocksDataContent() {
    return await axios.get(BASE_URL + '/markets-stocks-data-contents');
}