import axios from "axios";
import {BASE_URL} from "../../constants/API";

export async function getCfdHeader() {
    return await axios.get(BASE_URL + '/markets-cdf-headers?populate=image');
}

export async function getCfdDefinition() {
    return await axios.get(BASE_URL + '/markets-cfd-definitions');
}

export async function getCfdDataContent() {
    return await axios.get(BASE_URL + '/markets-cfd-data-contents');
}