import axios from "axios";
import {BASE_URL} from "../constants/API";

export async function getAffiliateProgramHeader() {
    return await axios.get(BASE_URL + '/affiliate-program-headers?populate=image');
}

export async function getAffiliateProgramIntro() {
    return await axios.get(BASE_URL + '/affiliate-program-intros?populate=pointerImage');
}

export async function getAffiliateProgramJoin() {
    return await axios.get(BASE_URL + '/affiliate-program-joins?populate=backgroundImage');
}

export async function getAffiliateProgramSteps() {
    return await axios.get(BASE_URL + '/affiliate-program-steps');
}