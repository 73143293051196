import '../../App.css';
import './Footer.css';
import FooterScreens from "./FooterScreens/FooterScreens";
import FooterSocialMedia from "./FooterSocialMedia/FooterSocialMedia";
import FooterCopyrights from "./FooterCopyrights/FooterCopyrights";
import {useEffect, useState} from "react";
import {MEDIA_BASE_URL} from "../../constants/API";
import {openExternalLink} from "../../services/AuthService";

function Footer({footers, language}) {
    const [footerScreens, setFooterScreens] = useState([]);
    const [socialMediaLogos, setSocialMediaLogos] = useState([]);
    const [copyrights, setCopyrights] = useState([]);
    const [splitRegulations, setSplitRegulations] = useState([{}]);

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        const screens = footers.screens
            .filter(screen => screen.isVisible)
            .map(screen => ({
                ...screen,
                subScreens: screen.subScreens.filter(subScreen => subScreen.isVisible === true)
            }));

        const socialMediaLogos = footers.socialMediaLogos
            .filter(image => image.isVisible === true)
            .map(image => ({
                ...image,
                logo: `${MEDIA_BASE_URL}${image.logo}`
            }));

        const copyrights = footers.copyrights.map(copyright => ({
            ...copyright,
            elements: copyright.elements.filter(element => element.isVisible === true),
        }));

        if (footers.RiskDisclosureLinks.length > 0) {
            const dividedStatements = divideRegulationStatement(
                footers[`regulations${formattedLanguage}`],
                [],
                footers.RiskDisclosureLinks
            );

            setSplitRegulations(dividedStatements);
        } else {
            setSplitRegulations([footers[`regulations${formattedLanguage}`]]);
        }

        setFooterScreens(screens);
        setSocialMediaLogos(socialMediaLogos);
        setCopyrights(copyrights);
    }, [footers, language]);

    function divideRegulationStatement(initialStatement, statements, riskDisclosureLinks) {
        if(riskDisclosureLinks.length === 0) {
            statements.push({
                expression: initialStatement,
                hasPath: false,
                path: null
            });

             return statements;
        }

        const firstLink = riskDisclosureLinks[0];
        const splitRegulations = initialStatement.split(firstLink[`expression-${language}`]);

        statements.push({
            expression: splitRegulations[0],
            hasPath: false,
            path: null,
        });

        statements.push({
            expression: firstLink[`expression-${language}`],
            hasPath: true,
            path: firstLink.path
        });

        const restOfDisclosureLinks = riskDisclosureLinks.slice(1, riskDisclosureLinks.length);
        const restOfStatement = splitRegulations.slice(1, splitRegulations.length).join(' ');

        return divideRegulationStatement(restOfStatement, statements, restOfDisclosureLinks);
    }

    function onRegulationPathClickHandler(url) {
        openExternalLink(`${MEDIA_BASE_URL}${url}`);
    }

    return (
        <>
            {footers && splitRegulations.length > 0 && (
                <div className='component-container footer-container'>
                    <div className='for-mobile'>
                        {socialMediaLogos.length > 0 && (
                            <FooterSocialMedia
                                logos={socialMediaLogos}
                                language={formattedLanguage}
                            />
                        )}
                    </div>

                    <div className='for-mobile'>
                        {footerScreens.length > 0 && (
                            <FooterScreens
                                screens={footerScreens}
                                language={language}
                            />
                        )}
                    </div>

                    <div className='component-data-container'>
                        <div className='footer-regulations body-four'>
                            {splitRegulations.map((regulation, index) => (
                                <span key={index}>
                                    {regulation.hasPath === true && (
                                        <span
                                            className='footer-regulation-path'
                                            onClick={() => onRegulationPathClickHandler(regulation.path)}
                                        >
                                            {regulation.expression}
                                        </span>
                                    )}

                                    {regulation.hasPath === false && (
                                        <span>{regulation.expression}</span>
                                    )}
                                </span>
                            ))}
                        </div>

                        <div className='footer-regulations-separator'></div>

                        <div className='footer-data-container'>
                            <div className='footer-data-risk-warning'>
                                <h4>{footers[`title${formattedLanguage}`]}</h4>
                                <div className='footer-risk-warning-description body-four'>
                                    {footers[`description${formattedLanguage}`]}
                                </div>
                            </div>

                            <div className='footer-data-pages for-web'>
                                {footerScreens.length > 0 && (
                                    <FooterScreens
                                        screens={footerScreens}
                                        language={language}
                                    />
                                )}

                                {socialMediaLogos.length > 0 && (
                                    <FooterSocialMedia logos={socialMediaLogos}/>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='for-web footer-separator'></div>
                    <div className='for-mobile component-data-container footer-separator'></div>

                    {copyrights.length > 0 && (
                        <FooterCopyrights copyrights={copyrights} language={language} />
                    )}
                </div>
            )}
        </>
    );
}

export default Footer;