import { useEffect } from "react";

const DYNTUBE_SCRIPT_ID = "dyntube-script";

function MarketNewsVideo() {
    // ARABIC VIDEO

    // useEffect(() => {
    //     // Check if the script is already added
    //     if (!document.getElementById(DYNTUBE_SCRIPT_ID)) {
    //         const script = document.createElement("script");
    //         script.type = "text/javascript";
    //         script.async = true;
    //         script.src = "https://embed.dyntube.com/v1.0/dyntube.js";
    //         script.id = DYNTUBE_SCRIPT_ID;
    //         script.onerror = () => console.error("Dyntube script load error");
    //         document.head.appendChild(script);
    //     }
    //
    //     // No cleanup needed since script remains in the DOM
    // }, []);

    // return <div data-dyntube-key="lOBi0P9WAEWkaDWn2J96A"></div>;

    // ENGLISH VIDEO
    useEffect(() => {
        if (window._dyntube_v1_init === undefined) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://embed.dyntube.com/v1.0/dyntube.js';
            document.head.appendChild(script);
        }
    }, []);

    return (
        <div>
            <div data-dyntube-key="qe2v2YGDEaLV594Od9dJA"></div>
        </div>
    );
}

export default MarketNewsVideo;