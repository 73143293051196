import '../../../../../App.css';
import './JobOpenings.css';
import NextButtonImage from '../../../../../assets/clarets/claret-next-white.svg';
import PreviousButtonImage from '../../../../../assets/clarets/claret-previous-white.svg';
import CustomButton from "../../../../ui/CustomButton/CustomButton";
import {useState} from "react";

function JobOpenings({ jobOpenings, onApplyFormClicked, language, buttonTitle }) {
    const [index, setIndex] = useState(0);
    const [direction, setDirection] = useState('next');
    const [active, setActive] = useState(true);
    const [showJobForm, setShowJobForm] = useState(false);

    function onNextJobOpeningHandler() {
        setShowJobForm(false);
        onApplyFormClicked({ showJobForm: false, jobId: 0 });
        setActive(false);
        setDirection('next');

        setTimeout(() => {
            setIndex(index === jobOpenings.length - 1 ? 0 : index + 1);
            setActive(true);
        }, 1000);
    }

    function onPreviousJobOpeningHandler() {
        setShowJobForm(false);
        onApplyFormClicked({ showJobForm: false, jobId: 0 });
        setActive(false);
        setDirection('previous');

        setTimeout(() => {
            setIndex(index === 0 ? jobOpenings.length - 1 : index - 1);
            setActive(true);
        }, 1000);
    }

    function onApplyNowHandler() {
        setShowJobForm(!showJobForm);
        onApplyFormClicked && onApplyFormClicked({ showJobForm: !showJobForm, jobId: jobOpenings[index].id });
    }

    return (
        <div className='careers-job-openings-container'>
            <div className={`
                ${direction === 'previous' ? 'slide-out-right' : 'slide-out-left'}
                ${active && direction === 'previous' && 'slide-in-left'}
                ${active && direction === 'next' && 'slide-in-right'}`}
            >
                <h3 className='careers-job-openings-title'>
                    {jobOpenings[index][`title-${language}`]}
                </h3>

                <div className='careers-job-openings-description-container'>
                    {language === 'ar' && (
                        <img
                            className='careers-job-openings-image clarets-hover'
                            src={NextButtonImage}
                            alt='Next Button'
                            onClick={onNextJobOpeningHandler}
                        />
                    )}

                    {language !== 'ar' && (
                        <img
                            className='careers-job-openings-image clarets-hover'
                            src={PreviousButtonImage}
                            alt='Previous Button'
                            onClick={onPreviousJobOpeningHandler}
                        />
                    )}

                    <div className='careers-job-openings-description body-one'>
                        {jobOpenings[index][`description-${language}`]}
                    </div>

                    {language === 'ar' && (
                        <img
                            className='careers-job-openings-image clarets-hover'
                            src={PreviousButtonImage}
                            alt='Previous Button'
                            onClick={onPreviousJobOpeningHandler}
                        />
                    )}

                    {language !== 'ar' && (
                        <img
                            className='careers-job-openings-image clarets-hover'
                            src={NextButtonImage}
                            alt='Next Button'
                            onClick={onNextJobOpeningHandler}
                        />
                    )}
                </div>

                <div className='careers-job-openings-button-container'>
                    <CustomButton title={buttonTitle} onClick={onApplyNowHandler} hasNoGradient={true}/>
                </div>
            </div>
        </div>
    );
}

export default JobOpenings;