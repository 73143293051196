import axios from "axios";
import {BASE_URL} from "../constants/API";

export async function getAccountsHeader() {
    return await axios.get(BASE_URL + '/accounts-headers?populate=image');
}

export async function getAccountsServices() {
    return await axios.get(BASE_URL + '/accounts-services?populate=leftBorderImage&populate=rightBorderImage&populate=pointerImage&populate=backgroundImage');
}

export async function getAccountsTypes() {
    return await axios.get(BASE_URL + '/accounts-types?populate=servicePointerImage&populate=overnightChargesDocument');
}

export async function getAccountsTypesForm() {
    return await axios.get(BASE_URL + '/accounts-type-forms');
}