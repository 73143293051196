import '../../../App.css';
import './HelpCenterQuestions.css';
import HelpCenterQuestion from "./help-center-question/HelpCenterQuestion";
import useVisibility from "../../../hooks/useVisibility";

function HelpCenterQuestions({content, language}) {
    const [isVisible, containerRef] = useVisibility();

    return (
        <>
            {content && (
                <div
                    className={`component-container-no-full-height ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='help-center-questions-data-container component-data-container'>
                        <h2>{content[`title${language}`]}</h2>

                        <h3 className='help-center-questions-subtitle'>{content[`subtitle${language}`]}</h3>

                        {content[`questions${language}`].map((q, index) => (
                            <HelpCenterQuestion key={index} question={q.question} answer={q.answer} />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

export default HelpCenterQuestions;