import Fingertips from "../../components/homepage/fingertips/Fingertips";
import HomepageCard from "../../components/homepage/card/HomepageCard";
import './Homepage.css';
import AccountTypes from "../../components/homepage/account-types/AccountTypes";
import WhyGivtrade from "../../components/homepage/why-givtrade/WhyGivtrade";
import TradingPlatforms from "../../components/homepage/trading-platforms/TradingPlatforms";
import FundingMethods from "../../components/homepage/funding-methods/FundingMethods";
import TrustPilot from "../../components/homepage/trust-pilot/TrustPilot";
import {useEffect, useState} from "react";
import {
    getHomepageAccountTypes,
    getHomepageCards, getHomepageFundingMethods,
    getHomepageHeader, getHomepageTradingPlatforms,
    getHomepageWhyGivTrade
} from "../../services/HomepageService";
import LoadingSpinner from "../../components/ui/loading-spinner/LoadingSpinner";
import {useNavigate} from "react-router-dom";

function Homepage({language}) {
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                const [
                    fingertips,
                    cardDetails,
                    accountTypes,
                    whyGivTrade,
                    tradingPlatforms,
                    fundingMethods
                ] = await Promise.all([
                    getHomepageHeader(),
                    getHomepageCards(),
                    getHomepageAccountTypes(),
                    getHomepageWhyGivTrade(),
                    getHomepageTradingPlatforms(),
                    getHomepageFundingMethods()
                ]);

                const fingertipsData = fingertips.data.data.map(fingertip => ({
                    titleEn: fingertip.titleEn,
                    titleAr: fingertip.titleAr,
                    subtitleEn: fingertip.subtitleEn,
                    subtitleAr: fingertip.subtitleAr,
                    descriptionEn: fingertip.descriptionEn,
                    descriptionAr: fingertip.descriptionAr,
                    buttonTitleEn: fingertip.buttonTitleEn,
                    buttonTitleAr: fingertip.buttonTitleAr,
                    buttonLink: fingertip.buttonLink,
                    backgroundImageUrl: `${fingertip.backgroundImageUrl.url}`,
                    backgroundImageMobileUrl: `${fingertip.backgroundImageMobileUrl.url}`
                }));

                const cardDetailsData = cardDetails.data.data.map(card => ({
                    titleEn: card.titleEn,
                    titleAr: card.titleAr,
                    descriptionEn: card.descriptionEn,
                    descriptionAr: card.descriptionAr,
                    imageUrl: `${card.imageUrl.url}`,
                    globalTitlePartOneEn: card.globalTitlePartOneEn,
                    globalTitlePartOneAr: card.globalTitlePartOneAr,
                    globalTitlePartTwoEn: card.globalTitlePartTwoEn,
                    globalTitlePartTwoAr: card.globalTitlePartTwoAr,
                    buttonTitleEn: card.buttonTitleEn,
                    buttonTitleAr: card.buttonTitleAr,
                    backgroundImageUrl: card.backgroundImage.url,
                    onLearnMoreClick: () => navigate('/markets/forex')
                }));

                const accountTypesData = accountTypes.data.data
                    .filter(accountType => accountType.isVisible === true)
                    .map(accountType => ({
                        titleEN: accountType.titleEN,
                        titleAR: accountType.titleAR,
                        descriptionsEN: accountType.descriptionsEN,
                        descriptionsAR: accountType.descriptionsAR,
                        buttonTitleEN: accountType.buttonTitleEN,
                        buttonTitleAR: accountType.buttonTitleAR,
                        globalTitlePartOneEN: accountType.globalTitlePartOneEN,
                        globalTitlePartOneAR: accountType.globalTitlePartOneAR,
                        globalTitlePartTwoEN: accountType.globalTitlePartTwoEN,
                        globalTitlePartTwoAR: accountType.globalTitlePartTwoAR,
                        onClick: () => navigate('/accounts')
                    }));

                const whyGivTradeData = whyGivTrade.data.data.map(d => ({
                    ...d,
                    descriptions: d.descriptions.filter(desc => desc.isVisible === true)
                }));

                const tradingPlatformsData = tradingPlatforms.data.data;

                const fundingMethodsData = fundingMethods.data.data;

                setContent({
                    fingertips: fingertipsData,
                    cardDetails: cardDetailsData,
                    accountTypes: accountTypesData,
                    whyGivTrade: whyGivTradeData,
                    tradingPlatforms: tradingPlatformsData,
                    fundingMethods: fundingMethodsData
                });

                setLoading(false);
            } catch (error) {}
        }

        fetchData();
    }, [navigate]);

    if(loading === true) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <div>
            <Fingertips fingertips={content.fingertips} language={language} />

            <WhyGivtrade whyGivTrade={content.whyGivTrade} language={language} />

            <div
                style={{
                    backgroundImage: `url(${content.cardDetails[0].backgroundImageUrl})`,
                }}
                className='homepage-cards-container homepage-padding'
            >
                <HomepageCard cardDetails={content.cardDetails} language={language} />
            </div>

            <AccountTypes accountTypes={content.accountTypes} language={language} />

            <TradingPlatforms tradingPlatforms={content.tradingPlatforms} language={language} />

            <FundingMethods fundingMethods={content.fundingMethods} language={language} />

            <TrustPilot />
        </div>
    );
}

export default Homepage;