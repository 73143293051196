import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import { Colors } from './constants/Colors';
import Navbar from "./components/navbar/Navbar";
import Homepage from "./screens/Homepage/Homepage";
import WhoWeAre from "./screens/AboutUs/WhoWeAre/WhoWeAre";
import Careers from "./screens/AboutUs/Careers/Careers";
import Forex from "./screens/Markets/Forex/Forex";
import Cfd from "./screens/Markets/Cfd/Cfd";
import Blog from "./screens/ResourcesSupport/Blog/Blog";
import MarketAnalysis from "./screens/ResourcesSupport/MarketAnalysis/MarketAnalysis";
import HelpCenter from "./screens/ResourcesSupport/HelpCenter/HelpCenter";
import RegulatoryDocuments from "./screens/AboutUs/RegulatoryDocuments/RegulatoryDocuments";
import ContactUs from "./screens/AboutUs/ContactUs/ContactUs";
import {useEffect, useState} from "react";
import Footer from "./components/Footer/Footer";
import Accounts from "./screens/Accounts/Accounts";
import ScrollToTop from "./hooks/ScrollToTop";
import Platforms from "./screens/Platforms/Platforms";
import AffiliateProgram from "./screens/affiliate-program/AffiliateProgram";
import Stocks from "./screens/Markets/Stocks/Stocks";
import SplashScreen from "./components/ui/splash-screen/SplashScreen";
import {getFooter, getNavbar} from "./services/HomepageService";
import LoadingSpinner from "./components/ui/loading-spinner/LoadingSpinner";
import Offers from "./screens/Offers/Offers";
import TidioChatbot from "./components/tidio-chatbot/TidioChatbot";

function App() {
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);
    const [language, setLanguage] = useState(localStorage.getItem('language'));
    const [direction, setDirection] = useState('ltr');

    useEffect(() => {
        async function fetchData() {
            try {
                const [navbar, footer] =
                    await Promise.all([
                        getNavbar(),
                        getFooter()
                    ]);

                const navbarData = navbar.data.data[0];
                const footerData = footer.data.data[0];

                setContent({
                    navbar: navbarData,
                    footer: footerData
                });

                const storedLanguage = localStorage.getItem('language') || 'en';
                setLanguage(storedLanguage);

                const newDirection = storedLanguage === 'ar' ? 'rtl' : 'ltr';
                setDirection(newDirection);
                document.documentElement.setAttribute('dir', newDirection);

                setLoading(false);
            } catch (error) {}
        }

        fetchData();
    }, []);

    function onVideoEndHandler() {
        setShowSplashScreen(false);
    }

    function onContactUsChatHandler() {
        if (window.tidioChatApi) {
            window.tidioChatApi.open(); // Open the chatbot
        }
    }

    function onLanguageChangeHandler(language) {
        setLanguage(language);
        localStorage.setItem('language', language);

        const newDirection = language === 'ar' ? 'rtl' : 'ltr';
        setDirection(newDirection);
        document.documentElement.setAttribute("dir", newDirection);
    }

    return (
        <BrowserRouter future={{v7_relativeSplatPath: true}}>
            <ScrollToTop />
            <noscript>
                <iframe 
                    src="https://www.googletagmanager.com/ns.html?id=GTM-P9KC3MCH" 
                    height="0" 
                    width="0" 
                    style={{ display: "none", visibility: "hidden" }}
                ></iframe>
            </noscript>
            <div className='app-container' style={{
                '--color-green': Colors.green,
                '--color-green500': Colors.green500,
                '--color-green600': Colors.green600,
                '--color-green700': Colors.green700,
                '--color-black': Colors.black,
                '--color-gray': Colors.gray,
                '--color-darkgray': Colors.darkGray,
                '--color-darkgray500': Colors.darkGray500,
                '--color-darkgray-halfopacity': Colors.darkGrayHalfOpacity,
                '--color-darkgreen': Colors.darkGreen
            }}>
                {showSplashScreen ? (
                    <SplashScreen onVideoEnd={onVideoEndHandler} />
                ) : (
                    <>
                        {loading === true ? <LoadingSpinner /> : <Navbar navbar={content.navbar} language={language} onLanguageChange={onLanguageChangeHandler} />}
                        <div className='content-container'>
                            <Routes>
                                <Route path="*" element={ <Navigate to="/" /> } />
                                <Route path="/" element={ <Homepage language={language} /> } />

                                {/* About Us Routes */}
                                <Route path="/who-we-are" element={ <WhoWeAre language={language} /> } />
                                <Route path="/careers" element={ <Careers language={language} /> } />
                                <Route path='/regulatory-documents' element={ <RegulatoryDocuments language={language} /> } />
                                <Route path='/contact-us' element={ <ContactUs setShowChat={onContactUsChatHandler} language={language} /> } />

                                {/* Accounts Routes */}
                                <Route path='/accounts' element={ <Accounts language={language} /> } />

                                {/* Markets Routes */}
                                <Route path='/markets/forex' element={ <Forex language={language} /> } />
                                <Route path='/markets/cfd' element={ <Cfd language={language} /> } />
                                <Route path='/markets/stocks' element={ <Stocks language={language} /> } />

                                {/* Platforms Routes */}
                                <Route path='/platforms' element={ <Platforms language={language} /> } />

                                {/* Offers Routes */}
                                <Route path='/offers' element={ <Offers language={language} /> } />

                                {/* Resources and Support Routes */}
                                <Route path='/resources/blog' element={ <Blog language={language} /> } />
                                <Route path='/resources/market-analysis' element={ <MarketAnalysis language={language} /> } />
                                <Route path='/resources/help-center' element={ <HelpCenter language={language} /> } />

                                {/* Affiliate Program */}
                                <Route path='/affiliate-program' element={ <AffiliateProgram language={language} /> } />
                            </Routes>
                        </div>

                        <TidioChatbot />

                        {loading === true ? <LoadingSpinner /> : <Footer footers={content.footer} language={language} />}
                    </>
                )}
            </div>
        </BrowserRouter>
    );
}

export default App;
