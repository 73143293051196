import {useEffect, useState} from "react";
import {
    getMarketAnalysisDataContent,
    getMarketAnalysisHeader
} from "../../../services/resources-support/MarketAnalysisService";
import Header from "../../../components/ui/header/Header";
import MarketAnalysisTabs from "../../../components/resources-support/market-analysis/tabs/MarketAnalysisTabs";
import LoadingSpinner from "../../../components/ui/loading-spinner/LoadingSpinner";

function MarketAnalysis({language}) {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        async function fetchData() {
            try {
                const [header, slidersContent] =
                    await Promise.all([
                        getMarketAnalysisHeader(),
                        getMarketAnalysisDataContent()
                    ]);

                const headerData = header.data.data[0];

                const slidersContentData = slidersContent.data.data.map(d => ({
                    ...d,
                    content: d.content.filter(c => c.isVisible === true).map(c => ({
                        ...c,
                        document: {
                            ...c.document,
                            documents: c.document.documents.filter(doc => doc.isVisible === true)
                        }
                    }))
                }))[0];

                setContent({
                    header: headerData,
                    slidersContent: slidersContentData
                })

                setLoading(false);
            } catch (error) {}
        }

        fetchData();
    }, []);

    if(loading === true) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <>
            {content.header && (
                <div>
                    <Header
                        title={content.header[`title${formattedLanguage}`]}
                        subtitle={content.header[`subtitle${formattedLanguage}`]}
                        sourcePath={`${content.header.image.url}`}
                    />

                    <MarketAnalysisTabs slidersContent={content.slidersContent} language={language} />
                </div>
            )}
        </>
    );
}

export default MarketAnalysis;