import {useEffect, useState} from "react";
import {getFaqHeader, getFaqQuestions} from "../../../services/resources-support/HelpCenterService";
import Header from "../../../components/ui/header/Header";
import HelpCenterQuestions from "../../../components/resources-support/help-center/HelpCenterQuestions";
import LoadingSpinner from "../../../components/ui/loading-spinner/LoadingSpinner";

function HelpCenter({language}) {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        async function fetchData() {
            try {
                const [header, questions] =
                    await Promise.all([
                        getFaqHeader(),
                        getFaqQuestions()
                    ]);

                const headerData = header.data.data[0];

                const questionsData = questions.data.data.map(d => ({
                    ...d,
                    questions: d[`questions${formattedLanguage}`].filter(question => question.isVisible === true)
                }))[0];

                setContent({
                    header: headerData,
                    questions: questionsData
                });

                setLoading(false);
            } catch (error) {}
        }

        fetchData();
    }, []);

    if(loading === true) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <>
            {content.header && (
                <div>
                    <Header
                        title={content.header[`title${formattedLanguage}`]}
                        subtitle={content.header[`subtitle${formattedLanguage}`]}
                        sourcePath={`${content.header.image.url}`}
                    />

                    <HelpCenterQuestions content={content.questions} language={formattedLanguage} />
                </div>
            )}
        </>
    );
}

export default HelpCenter;