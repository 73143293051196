import '../../../App.css';
import './CustomButton.css';

function CustomButton({ title, onClick, backgroundColor, hasNoGradient }) {
    return (
        <div
            className={`button box-shadow custom-button-container ${hasNoGradient === true ? 'custom-button-color' : 'background-color-gradient'}`}
            style={backgroundColor ? { backgroundColor } : {}}
            onClick={onClick}
        >
            {title}
        </div>
    );
}

export default CustomButton;