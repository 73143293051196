import '../../../../App.css';
import './Values.css';
import CustomButton from "../../../ui/CustomButton/CustomButton";
import {openExternalLink} from "../../../../services/AuthService";
import useVisibility from "../../../../hooks/useVisibility";
import {MEDIA_BASE_URL} from "../../../../constants/API";
import ComponentTitle from "../../../ui/ComponentTitle/ComponentTitle";

function Values({value, language}) {
    const [isVisible, containerRef] = useVisibility();

    const formattedLanguage = language.toUpperCase();

    function onStartJourneyHandler() {
        openExternalLink(value.buttonLink);
    }

    return (
        <>
            {value && (
                <div
                    className={`component-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container'>
                        <ComponentTitle
                            titlePartOne={value[`titlePartOne${formattedLanguage}`]}
                            titlePartTwo={value[`titlePartTwo${formattedLanguage}`]}
                        />

                        <div className='values-container'>
                            {value.values.map((item, index) => (
                                <div className='values-item-container' key={index}>
                                    <div className='values-item-image-container'>
                                        <img
                                            className='values-item-image'
                                            src={`${MEDIA_BASE_URL}${item.imagePath}`}
                                            alt={item[`title-${language}`]}/>
                                    </div>
                                    <h3 className='values-item-title'>{item[`title-${language}`]}</h3>
                                    <div className='subtitle values-item-description'>{item[`description-${language}`]}</div>
                                </div>
                            ))}
                        </div>

                        <div className='values-button-container for-web'>
                            <CustomButton title={value[`buttonTitle${formattedLanguage}`]} onClick={onStartJourneyHandler}/>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Values;