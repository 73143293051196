import {useEffect, useState} from "react";
import {
    getRegulatoryDocuments,
    getRegulatoryDocumentsHeader
} from "../../../services/about-us/RegulatoryDocumentsService";
import Header from "../../../components/ui/header/Header";
import Documents from "../../../components/ui/documents/Documents";
import LoadingSpinner from "../../../components/ui/loading-spinner/LoadingSpinner";

function RegulatoryDocuments({language}) {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        async function fetchData() {
            try {
                const [header, document] =
                    await Promise.all([
                        getRegulatoryDocumentsHeader(),
                        getRegulatoryDocuments()
                    ]);

                const headerData = header.data.data[0];

                const documentData = document.data.data.map(document => ({
                    ...document,
                    documents: document.documents.filter(d => d.isVisible === true),
                }))[0];

                setContent({
                    header: headerData,
                    document: documentData
                });

                setLoading(false);
            } catch (error) {}
        }

        fetchData();
    }, []);

    if(loading === true) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <div>
            {content.header && <Header
                title={content.header[`title${formattedLanguage}`]}
                subtitle={content.header[`subtitle${formattedLanguage}`]}
                sourcePath={`${content.header.image.url}`}
            />}

            <Documents document={content.document} language={language} />
        </div>
    );
}

export default RegulatoryDocuments;