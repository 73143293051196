import '../../../../App.css';
import './Jobs.css';
import JobOpenings from "./job-openings/JobOpenings";
import useVisibility from "../../../../hooks/useVisibility";
import JobForm from "./job-form/JobForm";
import {useState} from "react";

function Jobs({jobs, language}) {
    const [isVisible, containerRef] = useVisibility();
    const [showJobForm, setShowJobForm] = useState(false);
    const [jobId, setJobId] = useState(0);

    const formattedLanguage = language.toUpperCase();

    function onShowFormHandler(showJobObject) {
        setShowJobForm(showJobObject.showJobForm);
        setJobId(showJobObject.jobId);
    }

    return (
        <>
            {jobs && (
                <div>
                    <div
                        style={{
                            backgroundImage: `url(${jobs.backgroundImage.url})`,
                        }}
                        className={`component-container careers-jobs-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                        ref={containerRef}
                    >
                        <div className='careers-jobs-data-container component-data-container'>
                            <h2 className='careers-jobs-title'>{jobs[`title${formattedLanguage}`]}</h2>

                            <JobOpenings
                                jobOpenings={jobs.jobOpenings}
                                onApplyFormClicked={onShowFormHandler}
                                language={language}
                                buttonTitle={jobs[`buttonTitle${formattedLanguage}`]}
                            />
                        </div>
                    </div>

                    {showJobForm && (
                        <div className='slide-down'>
                            <JobForm jobId={jobId}/>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default Jobs;