import '../../../../App.css';
import './Table.css';
import {openExternalLink} from "../../../../services/AuthService";
import CustomButton from "../../CustomButton/CustomButton";

function Table({content, language}) {
    function onButtonClickHandler() {
        openExternalLink(content.buttonLink);
    }

    return (
        <>
            {content && (
                <div>
                    {content[`tableTitle-${language}`] && (
                        <h2 className='sliders-table-title'>{content[`tableTitle-${language}`]}</h2>
                    )}

                    <div className='sliders-table-container box-shadow-all-sides'>
                        <table className='sliders-table'>
                            <thead className='sliders-table-header-container'>
                            <tr>
                                {content[`tableHeaders-${language}`].map((header, index) => (
                                    <th key={index} className='sliders-table-header'>{header}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {content.tableValues.map((item, index) => (
                                <tr key={index}>
                                    {item.map((cell, cellIndex) => (
                                        <td
                                            key={cellIndex}
                                            className={`sliders-table-cell ${index % 2 === 0 ? 'sliders-table-cell-even' : 'sliders-table-cell-odd'}`}
                                        >
                                            {cell}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    {content[`table2Headers-${language}`] && content[`table2Headers-${language}`].length > 0 && content.table2Values && content.table2Values.length > 0 && (
                        <>
                            {content[`table2Title-${language}`] && (
                                <h2 className='sliders-table-title'>{content[`table2Title-${language}`]}</h2>
                            )}

                            <div className='sliders-table-container box-shadow-all-sides'>
                                <table className='sliders-table'>
                                    <thead className='sliders-table-header-container'>
                                    <tr>
                                        {content[`table2Headers-${language}`].map((header, index) => (
                                            <th key={index} className='sliders-table-header'>{header}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {content.table2Values.map((item, index) => (
                                        <tr key={index}>
                                            {item.map((cell, cellIndex) => (
                                                <td
                                                    key={cellIndex}
                                                    className={`sliders-table-cell ${index % 2 === 0 ? 'sliders-table-cell-even' : 'sliders-table-cell-odd'}`}
                                                >
                                                    {cell}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}

                    <div className='sliders-table-description'>
                        {content[`tableDescription-${language}`]}
                    </div>

                    {content[`buttonTitle-${language}`] && content.buttonLink && (
                        <div className='sliders-button-container'>
                            <CustomButton
                                title={content[`buttonTitle-${language}`]}
                                onClick={onButtonClickHandler}
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default Table;