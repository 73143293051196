import '../../../App.css';
import './ContactUsSupport.css';
import useVisibility from "../../../hooks/useVisibility";
import {openEmailTo, openExternalLink} from "../../../services/AuthService";
import {TELEGRAM_BASE_URL, WHATSAPP_BASE_URL} from "../../../constants/API";

function ContactUsSupport({content, setShowChat, language}) {
    const [isVisible, containerRef] = useVisibility();

    const formattedLanguage = language.toUpperCase();

    function contactSupport(url) {
        openExternalLink(url);
    }

    return (
        <>
            {content && (
                <div
                    style={{
                        backgroundImage: `url(${content.backgroundImage.url})`,
                    }}
                    className={`contact-us-support-container component-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container'>
                        <h3 className='contact-us-support-title'>{content[`title${formattedLanguage}`]}</h3>
                    </div>

                    <div className='contact-us-support-contacts-container'>
                        <div className='contact-us-support-contact-container'>
                            <div className='contact-us-support-text-container'>
                                <div>
                                    <div className='contact-us-support-contact-title'>{content[`contactTitle${formattedLanguage}`]}</div>

                                    <div className='contact-us-support-contact-margin subtitle'>
                                        {content[`contactSubtitle${formattedLanguage}`]}
                                        <span className='contact-us-support-contact-email'>{content.contactEmail}</span>
                                    </div>

                                    <div
                                        className='contact-us-support-contact-margin contact-us-support-contact-address'>
                                        {content[`contactAddress${formattedLanguage}`]}
                                    </div>
                                </div>

                                <div>
                                    <div
                                        className='contact-us-support-send subtitle'
                                        onClick={() => openEmailTo('cs@givtrade.com')}
                                    >
                                        {content[`contactSendText${formattedLanguage}`]}
                                    </div>
                                </div>
                            </div>

                            <div className='contact-us-support-image-container'>
                                <img
                                    className='contact-us-support-image'
                                    src={`${content.contactImage.url}`}
                                    alt='Email'
                                />
                            </div>
                        </div>

                        <div className='contact-us-support-contact-container'>
                            <div className='contact-us-support-text-container'>
                                <div>
                                    <div className='contact-us-support-contact-title'>{content[`chatTitle${formattedLanguage}`]}</div>

                                    <div className='contact-us-support-contact-margin subtitle'>
                                        {content[`chatSubtitle${formattedLanguage}`]}
                                    </div>
                                </div>

                                <div>
                                    <div
                                        className='contact-us-support-send subtitle'
                                        onClick={setShowChat}
                                    >
                                        {content[`chatSendText${formattedLanguage}`]}
                                    </div>
                                </div>
                            </div>

                            <div className='contact-us-support-image-container'>
                                <img
                                    className='contact-us-support-image'
                                    src={`${content.chatImage.url}`}
                                    alt='Chat'
                                />
                            </div>
                        </div>

                        <div className='contact-us-support-contact-container'>
                            <div className='contact-us-support-text-container'>
                                <div>
                                    <div className='contact-us-support-contact-title'>{content[`whatsappTitle${formattedLanguage}`]}</div>

                                    <div className='contact-us-support-contact-margin subtitle'>
                                        {content[`whatsappSubtitle${formattedLanguage}`]}
                                    </div>
                                </div>

                                <div>
                                    <div
                                        className='contact-us-support-send subtitle'
                                        onClick={() => contactSupport(`${WHATSAPP_BASE_URL}${content.whatsappNumber}`)}
                                    >
                                        {content[`whatsappSendText${formattedLanguage}`]}
                                    </div>
                                </div>
                            </div>

                            <div className='contact-us-support-image-container'>
                                <img
                                    className='contact-us-support-image'
                                    src={`${content.whatsappImage.url}`}
                                    alt='Whatsapp'
                                />
                            </div>
                        </div>

                        <div className='contact-us-support-contact-container'>
                            <div className='contact-us-support-text-container'>
                                <div>
                                    <div className='contact-us-support-contact-title'>{content[`telegramTitle${formattedLanguage}`]}</div>

                                    <div className='contact-us-support-contact-margin subtitle'>
                                        {content[`telegramSubtitle${formattedLanguage}`]}
                                    </div>
                                </div>

                                <div>
                                    <div
                                        className='contact-us-support-send subtitle'
                                        onClick={() => contactSupport(`${TELEGRAM_BASE_URL}${content.telegramNumber}`)}
                                    >
                                        {content[`telegramSendText${formattedLanguage}`]}
                                    </div>
                                </div>
                            </div>

                            <div className='contact-us-support-image-container'>
                                <img
                                    className='contact-us-support-image'
                                    src={`${content.telegramImage.url}`}
                                    alt='Whatsapp'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ContactUsSupport;