import '../../../../App.css';
import './Prizes.css';
import useVisibility from "../../../../hooks/useVisibility";
import {MEDIA_BASE_URL} from "../../../../constants/API";

function Prizes({prize, language}) {
    const [isVisible, containerRef] = useVisibility();

    return (
        <>
            {prize && (
                <div
                    style={{
                        backgroundImage: `url(${prize.backgroundImage.url})`,
                    }}
                    className={`prizes-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`} ref={containerRef}
                >
                    <div className='data-prize-container'>
                        {prize.prizes.map((item, index) => (
                            <div key={index}>
                                <div className='data-prize'>
                                    <img src={`${MEDIA_BASE_URL}${item.imagePath}`} alt='Prize'/>
                                    <div className='body-one prize-data-text'>{item[`description-${language}`]}</div>
                                </div>
                                {index !== prize.prizes.length - 1 && <div className='prizes-separator for-web'></div>}
                            </div>
                        ))}
                    </div>
                    <div>
                        <img className='for-web' src={`${prize.mainImage.url}`} alt='Golden Stars'/>
                        <img className='for-mobile prize-main-image' src={`${prize.mainImageMobile.url}`} alt='Golden Stars'/>
                    </div>
                </div>
            )}
        </>
    );
}

export default Prizes;