import '../../../App.css';
import './Documents.css';
import Document from './document/Document';
import {useEffect, useState} from "react";
import {MEDIA_BASE_URL} from "../../../constants/API";
import useVisibility from "../../../hooks/useVisibility";

function Documents({document, language}) {
    const [isVisible, containerRef] = useVisibility();

    const [wrappedDocuments, setWrappedDocuments] = useState([]);
    const [index, setIndex] = useState(0);
    const [direction, setDirection] = useState('next');
    const [active, setActive] = useState(true);
    const [isClaretsDisabled, setIsClaretsDisabled] = useState(false);
    const [chunkSize, setChunkSize] = useState(0);

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        // Dynamically set the chunk size based on the window size
        function updateChunkSize() {
            const width = window.innerWidth;
            setChunkSize(width <= 768 ? 1 : 6);
        }
        updateChunkSize();
        window.addEventListener("resize", updateChunkSize);
        return () => window.removeEventListener("resize", updateChunkSize);
    }, []);

    useEffect(() => {
        // Chunk the documents whenever chunkSize changes
        if (document && chunkSize) {
            const wrappedDocs = [];
            for (let i = 0; i < document.documents.length; i += chunkSize) {
                wrappedDocs.push(document.documents.slice(i, i + chunkSize));
            }
            setWrappedDocuments(wrappedDocs);
            setIsClaretsDisabled(wrappedDocs.length <= 1);
        }
    }, [chunkSize, document]); // Re-run only when chunkSize or document changes

    function onNextClaretHandler() {
        setActive(false);
        setDirection('next');

        setTimeout(() => {
            setIndex(index === wrappedDocuments.length - 1 ? 0 : index + 1);
            setActive(true);
        }, 1000);
    }

    function onPreviousClaretHandler() {
        setActive(false);
        setDirection('previous');

        setTimeout(() => {
            setIndex(index === 0 ? wrappedDocuments.length - 1 : index - 1);
            setActive(true);
        }, 1000);
    }

    return (
        <>
            {document && (
                <div
                    className={`component-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container'>
                        <div className='documents-header'>
                            { document[`title${formattedLanguage}`] && (<h1>{document[`title${formattedLanguage}`]}</h1>) }
                            { document[`subtitle${formattedLanguage}`] && (<h3>{document[`subtitle${formattedLanguage}`]}</h3>) }
                        </div>

                        <div className='wrapped-documents-container'>
                            {document.documents.length > 0 && (
                                <div className={`documents-container
                                ${direction === 'previous' ? 'slide-out-right' : 'slide-out-left'}
                                ${active && direction === 'previous' && 'slide-in-left'}
                                ${active && direction === 'next' && 'slide-in-right'}`}
                                >
                                    {document.documents.map((d, i) =>
                                        <Document key={i} file={d} language={language} />
                                    )}
                                </div>
                            )}

                            {/* SECTION REMOVED FOR HAVING REQUESTED TO HAVE ALL THE DOCUMENTS IN ONE PAGE */}
                            {/*<img*/}
                            {/*    className={`clarets-hover ${isClaretsDisabled && 'clarets-hover-disabled'}`}*/}
                            {/*    src={`${MEDIA_BASE_URL}/claret_previous_9d003a9059.svg`}*/}
                            {/*    alt='Previous Button'*/}
                            {/*    onClick={isClaretsDisabled ? undefined : onPreviousClaretHandler}*/}
                            {/*/>*/}

                            {/*{wrappedDocuments.length > 0 && (*/}
                            {/*    <div className={`documents-container*/}
                            {/*    ${direction === 'previous' ? 'slide-out-right' : 'slide-out-left'}*/}
                            {/*    ${active && direction === 'previous' && 'slide-in-left'}*/}
                            {/*    ${active && direction === 'next' && 'slide-in-right'}`}*/}
                            {/*    >*/}
                            {/*        {wrappedDocuments[index].map((d, i) =>*/}
                            {/*            <Document key={i} file={d}/>*/}
                            {/*        )}*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            {/*<img*/}
                            {/*    className={`clarets-hover ${isClaretsDisabled && 'clarets-hover-disabled'}`}*/}
                            {/*    src={`${MEDIA_BASE_URL}/claret_next_fd43c0c39e.svg`}*/}
                            {/*    alt='Next Button'*/}
                            {/*    onClick={isClaretsDisabled ? undefined : onNextClaretHandler}*/}
                            {/*/>*/}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Documents;