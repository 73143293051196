import {useEffect, useState} from "react";
import {getContactUsHeader, getContactUsSupport} from "../../../services/about-us/ContactUsService";
import Header from "../../../components/ui/header/Header";
import ContactUsSupport from "../../../components/about-us/contact-us/ContactUsSupport";
import LoadingSpinner from "../../../components/ui/loading-spinner/LoadingSpinner";

function ContactUs({setShowChat, language}) {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        async function fetchData() {
            try {
                const [header, contactUsSupport] =
                    await Promise.all([
                        getContactUsHeader(),
                        getContactUsSupport()
                    ]);

                const headerData = header.data.data[0];

                const contactUsSupportData = contactUsSupport.data.data[0];

                setContent({
                    header: headerData,
                    contactUsSupport: contactUsSupportData
                });

                setLoading(false);
            } catch (error) {}
        }

        fetchData();
    }, []);

    if(loading === true) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <>
            {content.header && (
                <div>
                    <Header
                        title={content.header[`title${formattedLanguage}`]}
                        subtitle={content.header[`subtitle${formattedLanguage}`]}
                        sourcePath={`${content.header.image.url}`}
                    />

                    <ContactUsSupport
                        content={content.contactUsSupport}
                        setShowChat={setShowChat}
                        language={language}
                    />
                </div>
            )}
        </>
    );
}

export default ContactUs;