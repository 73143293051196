import '../../../App.css';
import './OurAppCard.css';
import useVisibility from "../../../hooks/useVisibility";
import {MEDIA_BASE_URL} from "../../../constants/API";

function OurAppCard({content, language}) {
    const [isVisible, containerRef] = useVisibility();

    return (
        <>
            {content && (
                <div
                    className={`component-container-no-full-height ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div
                        className='component-data-container card box-shadow-all-sides background-color-gradient our-app-card-container'>
                        <div className='our-app-card-text-container'>
                            <h3 className='our-app-card-title'>{content[`title${language}`]}</h3>
                            <h5 className='our-app-card-subtitle'>{content[`subtitle${language}`]}</h5>
                            <div className='our-app-card-features-container'>
                                {content[`features${language}`].map((feature, index) => (
                                    <div key={index} className='our-app-card-feature'>
                                        <img
                                            src={`${MEDIA_BASE_URL}${content.featuresPointerImagePath}`}
                                            alt='Feature Sign'
                                        />
                                        <div className='our-app-card-feature-text'>{feature}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='our-app-card-image-container'>
                            <img src={`${content.image.url}`} alt='Card Phone' />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default OurAppCard;