import '../../../App.css';
import './AccountTypes.css';
import AccountType from "./account-type/AccountType";
import ComponentTitle from "../../ui/ComponentTitle/ComponentTitle";
import useVisibility from "../../../hooks/useVisibility";

function AccountTypes({accountTypes, language}) {
    const [isVisible, containerRef] = useVisibility();

    const formattedLanguage = language.toUpperCase();

    return (
        <>
            {accountTypes && (
                <div className={`component-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`} ref={containerRef}>
                    <div className='component-data-container'>
                        <ComponentTitle
                            titlePartOne={accountTypes[0][`globalTitlePartOne${formattedLanguage}`]}
                            titlePartTwo={accountTypes[0][`globalTitlePartTwo${formattedLanguage}`]}
                        />

                        <div className='account-type-cards'>
                            {accountTypes.map((accountType, index) =>
                                <AccountType
                                    key={index}
                                    accountType={accountType}
                                    language={formattedLanguage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default AccountTypes;