import {useEffect, useState} from "react";
import Header from "../../../components/ui/header/Header";
import {getCfdDataContent, getCfdDefinition, getCfdHeader} from "../../../services/markets/CfdService";
import Definition from "../../../components/markets/definition/Definition";
import Sliders from "../../../components/ui/tables-slider/sliders/Sliders";
import LoadingSpinner from "../../../components/ui/loading-spinner/LoadingSpinner";

function Cfd({language}) {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        async function fetchData() {
            try {
                const [
                    header,
                    definition,
                    slidersContent
                ] = await Promise.all([
                    getCfdHeader(),
                    getCfdDefinition(),
                    getCfdDataContent()
                ]);

                const headerData = header.data.data[0];
                const definitionData = definition.data.data[0];
                const slidersContentData = slidersContent.data.data[0];

                setContent({
                    header: headerData,
                    definition: definitionData,
                    slidersContent: slidersContentData
                });

                setLoading(false);
            } catch (error) {}
        }

        fetchData();
    }, []);

    if(loading === true) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <>
            {content.header && content.definition && content.slidersContent && (
                <div>
                    <Header
                        title={content.header[`title${formattedLanguage}`]}
                        subtitle={content.header[`subtitle${formattedLanguage}`]}
                        sourcePath={`${content.header.image.url}`}
                    />

                    {/*<Definition definition={content.definition} />*/}

                    <Sliders
                        slidersContent={content.slidersContent}
                        isMarket={true}
                        isBlog={false}
                        isMarketAnalysis={false}
                        language={language}
                    />
                </div>
            )}
        </>
    );
}

export default Cfd;