import '../../../App.css';
import './OurAppIntro.css';
import CustomButton from "../../ui/CustomButton/CustomButton";
import useVisibility from "../../../hooks/useVisibility";
import {openExternalLink} from "../../../services/AuthService";

function OurAppIntro({intro, language}) {
    const [isVisible, containerRef] = useVisibility();

    function onButtonClickHandler() {
        openExternalLink(intro.buttonLink);
    }

    return (
        <>
            {intro && (
                <div
                    className={`component-container-no-full-height ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container platforms-intro-container'>
                        <h2 className='platforms-intro-title'>{intro[`title${language}`]}</h2>
                        <h3 className='platforms-intro-subtitle'>{intro[`subtitle${language}`]}</h3>
                        <div className='platforms-intro-definitions-container'>
                            {intro[`descriptions${language}`].map((description, index) => (
                                <div
                                    key={index}
                                    className='body-one platforms-intro-description'
                                >
                                    {description}
                                </div>
                            ))}
                        </div>
                        <div className='platforms-intro-button-container'>
                            <CustomButton
                                title={intro[`buttonTitle${language}`]}
                                onClick={() => onButtonClickHandler()}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default OurAppIntro;