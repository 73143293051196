import '../../../App.css';
import './FingertipsTest.css';
import CustomButton from "../../ui/CustomButton/CustomButton";
import {openExternalLink} from "../../../services/AuthService";
import TrustPilot from "../trust-pilot/TrustPilot";

function Fingertips({fingertips, language}) {
    function onButtonClickHandler() {
        openExternalLink(fingertips[0].buttonLink);
    }

    const formattedLanguage = language.charAt(0).toUpperCase() + language.slice(1);

    return (
        <div className='fingertips-container'>
            <video autoPlay loop muted playsInline className='fingertips-video for-web'>
                <source src={fingertips[0].backgroundImageUrl} type='video/mp4'/>
                Your browser does not support the video tag.
            </video>

            <video autoPlay loop muted playsInline className='fingertips-video for-mobile'>
                <source src={fingertips[0].backgroundImageMobileUrl} type='video/mp4'/>
                Your browser does not support the video tag.
            </video>

            <div className='fingertips-data-container'>
                <div className="fingertips-titles">
                    <h2 className='fingertips-title'>{fingertips[0][`title${formattedLanguage}`]}</h2>
                    <h2 className='fingertips-title'>{fingertips[0][`subtitle${formattedLanguage}`]}</h2>
                    <div className='subtitle fingertips-data-text'>{fingertips[0][`description${formattedLanguage}`]}</div>
                    <div className='fingertips-button-container'>
                        <CustomButton title={fingertips[0][`buttonTitle${formattedLanguage}`]} onClick={onButtonClickHandler}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Fingertips;