import axios from "axios";
import {BASE_URL} from "../../constants/API";

export async function getCareersHeader() {
    return await axios.get(BASE_URL + '/careers-headers?populate=image');
}

export async function getCareersJoinUs() {
    return await axios.get(BASE_URL + '/careers-join-uses');
}

export async function getCareersJobs() {
    return await axios.get(BASE_URL + '/careers-jobs?populate=backgroundImage');
}

export async function getCareersCulture() {
    return await axios.get(BASE_URL + '/careers-cultures');
}