import '../../../App.css';
import './OffersWhyGivTrade.css';
import OffersWhyGivTradeIcon from "./offers-why-givtrade-icon/OffersWhyGivTradeIcon";
import useVisibility from "../../../hooks/useVisibility";

function OffersWhyGivTrade({content, language}) {
    const [isVisible, containerRef] = useVisibility();

    const formattedLanguage = language.toUpperCase();

    return (
        <>
            {content && (
                <div
                    className={`component-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container'>
                        <h2 className='offers-why-givtrade-title'>{content[`title${formattedLanguage}`]}</h2>

                        <h3 className='offers-why-givtrade-subtitle'>{content[`subtitle${formattedLanguage}`]}</h3>

                        <div className='offers-why-givtrade-icons-container'>
                            {content.icons.map((icon, index) => (
                                <OffersWhyGivTradeIcon
                                    key={index}
                                    icon={icon}
                                    language={language}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default OffersWhyGivTrade;