import '../../../../App.css';
import './JoinUs.css';
import useVisibility from "../../../../hooks/useVisibility";

function JoinUs({content, language}) {
    const [isVisible, containerRef] = useVisibility();

    const formattedLanguage = language.toUpperCase();

    return (
        <>
            {content && (
                <div
                    className={`join-us-container component-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container'>
                        <h2 className='join-us-title'>{content[`title${formattedLanguage}`]}</h2>
                        <h3 className='join-us-subtitle'>{content[`subtitle${formattedLanguage}`]}</h3>
                        <div className='join-us-description body-one'>{content[`description${formattedLanguage}`]}</div>
                    </div>
                </div>
            )}
        </>
    )
}

export default JoinUs;