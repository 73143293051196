import '../../../App.css';
import './Header.css';
import useVisibility from "../../../hooks/useVisibility";
import CustomButton from "../CustomButton/CustomButton";

function Header({ title, secondTitle, subtitle, sourcePath, opacity, buttonTitle, buttonOnClick }) {
    const [isVisible, containerRef] = useVisibility();

    return (
        <div
            className={`header-container background-image-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
            ref={containerRef}
            style={{
                backgroundImage: `url(${sourcePath})`
            }}
        >
            {opacity && (
                <div
                    className='header-overlay'
                    style={{
                        backgroundColor: `rgba(0, 0, 0, ${1 - opacity})`
                    }}
                />
            )}

            <h1 className='header-text'>{title}</h1>
            {secondTitle && <h1 className='header-text header-second-title'>{secondTitle}</h1>}
            {subtitle && <h3 className='header-text'>{subtitle}</h3>}
            {buttonTitle && (
                <div className='header-button-container'>
                    <CustomButton
                        title={buttonTitle}
                        hasNoGradient={true}
                        onClick={buttonOnClick}
                    />
                </div>
            )}
        </div>
    );
}

export default Header;