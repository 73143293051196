// export const BASE_URL = 'http://localhost:1337/api';
export const BASE_URL = 'https://jolly-flame-191fd02d05.strapiapp.com/api';

// export const MEDIA_BASE_URL = 'http://localhost:1337';
export const MEDIA_BASE_URL = 'https://jolly-flame-191fd02d05.media.strapiapp.com';

// export const EMAIL_SERVER_BASE_URL = 'http://localhost:5001';
export const EMAIL_SERVER_BASE_URL = 'https://sv-email-server-4.onrender.com';

export const WHATSAPP_BASE_URL = 'https://wa.me/';
export const TELEGRAM_BASE_URL = 'https://t.me/';