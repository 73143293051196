import '../../../../App.css';
import './Document.css';
import {MEDIA_BASE_URL} from "../../../../constants/API";
import {downloadDocument} from "../../../../services/DownloadDocumentService";

function Document({file, language}) {
    function openDocument() {
        downloadDocument(file.path, file.name);
    }

    return (
        <div className='document' onClick={openDocument}>
            <div className='document-logo-container'>
                <img
                    className='document-logo'
                    src={`${MEDIA_BASE_URL}/pdf_88d3fd23bd.svg`}
                    alt='Document Logo'
                />
            </div>
            <div className='document-header-container'>
                <h5>{file[`title-${language}`]}</h5>
                <div className='body-two'>{file[`subtitle-${language}`]}</div>
            </div>
        </div>
    )
}

export default Document;