import axios from "axios";
import {BASE_URL} from "../constants/API";

export async function getHomepageCards() {
    return await axios.get(BASE_URL + '/homepage-cards?isVisible=true&populate=imageUrl&populate=backgroundImage');
}

export async function getHomepageAccountTypes() {
    return await axios.get(BASE_URL + '/homepage-account-types?isVisible=true');
}

export async function getHomepageHeader() {
    return await axios.get(BASE_URL + '/homepage-headers?populate=backgroundImageUrl&populate=backgroundImageMobileUrl');
}

export async function getHomepageWhyGivTrade() {
    return await axios.get(BASE_URL + '/homepage-why-givtrades?populate=mainImage&populate=mainImageMobile&populate=highlightImage&populate=backgroundImage');
}

export async function getHomepageTradingPlatforms() {
    return await axios.get(BASE_URL + '/homepage-trading-platforms?populate=mainImage&populate=backgroundImage');
}

export async function getHomepageFundingMethods() {
    return await axios.get(BASE_URL + '/homepage-funding-methods?populate=caracsPointerImage&populate=separatorImageBottom&populate=separatorImageTop&populate=backgroundImage');
}

export async function getFooter() {
    return await axios.get(BASE_URL + '/footers');
}

export async function getNavbar() {
    return await axios.get(BASE_URL + '/navbars');
}