import '../../../../App.css';
import './Sliders.css';
import {useEffect, useRef, useState} from "react";
import Table from "../table/Table";
import useVisibility from "../../../../hooks/useVisibility";
import {MEDIA_BASE_URL} from "../../../../constants/API";
import Documents from "../../documents/Documents";
import CustomButton from "../../CustomButton/CustomButton";
import {openExternalLink} from "../../../../services/AuthService";
import MarketNewsVideo from "../../../video/MarketNewsVideo";
import Definition from "../../../markets/definition/Definition";

function Sliders({ slidersContent, isMarket, isBlog, isMarketAnalysis, language }) {
    const [activeTab, setActiveTab] = useState(null);
    const [tabs, setTabs] = useState(null);
    const [activeContent, setActiveContent] = useState(null);
    const [isPaused, setIsPaused] = useState(true);

    const videoRef = useRef(null);

    const [isVisible, containerRef] = useVisibility();

    useEffect(() => {
        const tabs = slidersContent.content.map(d => d.tab);
        setTabs(tabs);

        setActiveTab(tabs[0].id);

        setActiveContent(
            slidersContent.content.filter(c => c.tab.id === tabs[0].id)[0]
        );
    }, [slidersContent]);

    function onTabClickHandler(tab) {
        setActiveTab(tab.id);
        setActiveContent(slidersContent.content.filter(c => c.tab.id === tab.id)[0]);
        setIsPaused(false);
    }

    function toggleVideoPlayback() {
        if (videoRef.current) {
            if (isPaused === true) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
            setIsPaused(!isPaused);
        }
    }

    function onArticleClickHandler(url) {
        openExternalLink(url);
    }

    return (
        <>
            {slidersContent && tabs && activeTab && (
                <div
                    className={`markets-sliders-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    {isMarket === true && (
                        <Definition definition={activeContent.definition} language={language} />
                    )}

                    {activeTab !== 'null' && (
                        <div className='markets-sliders-tabs'>
                            {tabs.map((tab, index) => (
                                <h3
                                    key={index}
                                    className={`markets-sliders-tab ${activeTab === tab.id && 'markets-sliders-active-tab'} ${activeTab !== tab.id && 'markets-sliders-inactive-tab'}`}
                                    onClick={() => onTabClickHandler(tab)}
                                >
                                    {tab[`name-${language}`]}
                                </h3>
                            ))}
                        </div>
                    )}

                    {isMarket === true && (
                        <div className='markets-sliders-content'>
                            <Table content={activeContent} language={language} />
                        </div>
                    )}

                    {isBlog === true && (
                        <div className='articles-container'>
                            {activeContent.articles.map((article, index) => (
                                <div
                                    key={index}
                                    className='article-container'
                                    onClick={() => onArticleClickHandler(article.articleLink)}
                                >
                                    <img
                                        className='article-image for-mobile'
                                        src={`${MEDIA_BASE_URL}${article.articleImage}`}
                                        alt='Article Banner'
                                    />

                                    <div className='article-text'>
                                        <div className='article-title'>
                                            {article[`articleTitle-${language}`]}
                                        </div>

                                        <div className='article-description'>
                                            {article[`articleDescription-${language}`]}
                                        </div>
                                    </div>

                                    <img
                                        className='article-image for-web'
                                        src={`${MEDIA_BASE_URL}${article.articleImage}`}
                                        alt='Article Banner'
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                    {isMarketAnalysis === true && activeContent.document.documents.length > 0 && (
                        <Documents document={activeContent.document} language={language} />
                    )}

                    <div className={`${isMarketAnalysis === true && activeContent.hasVideo === true ? 'sliders-video-container' : 'sliders-video-container-hide'}`} >
                        <MarketNewsVideo />
                    </div>

                    {/*{isMarketAnalysis === true && activeContent.hasVideo === true && (*/}
                    {/*    <div>*/}
                    {/*        <video autoPlay loop playsInline className='sliders-video' ref={videoRef}>*/}
                    {/*            <source src={`${slidersContent.video.url}`}/>*/}
                    {/*            Your browser does not support the video tag.*/}
                    {/*        </video>*/}
                    {/*        <div className='sliders-video-button-container'>*/}
                    {/*            <CustomButton title={isPaused ? 'Play' : 'Pause'} onClick={toggleVideoPlayback} />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            )}
        </>
    );
}

export default Sliders;