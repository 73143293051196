export const Colors = {
    green: '#15b84d',
    green500: '#09d674',
    green600: '#036c31',
    green700: '#024620',
    black: '#000000',
    gray: '#F5F5F5',
    darkGray: '#E7E7E7',
    darkGray500: '#E5E5E5',
    darkGrayHalfOpacity: '#E7E7E750',
    darkGreen: '#005A5AB2'
};