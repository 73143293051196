import '../../../../App.css';
import Sliders from "../../../ui/tables-slider/sliders/Sliders";

function BlogTabs({slidersContent, language}) {
    return (
        <>
            {slidersContent && (
                <div className='component-container-no-full-height'>
                    <div className='component-data-container'>
                        <Sliders
                            slidersContent={slidersContent}
                            isMarket={false}
                            isBlog={true}
                            isMarketAnalysis={false}
                            language={language}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default BlogTabs;