import {useEffect, useState} from "react";
import {getOffersBenefits, getOffersHeader, getOffersSteps, getOffersWhyGivTrade} from "../../services/OffersService";
import LoadingSpinner from "../../components/ui/loading-spinner/LoadingSpinner";
import Header from "../../components/ui/header/Header";
import {openExternalLink} from "../../services/AuthService";
import OffersWhyGivTrade from "../../components/offers/offers-why-givtrade/OffersWhyGivTrade";
import OffersSteps from "../../components/offers/offers-steps/OffersSteps";
import OffersBenefits from "../../components/offers/offers-benefits/OffersBenefits";

function Offers({language}) {
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState({});

    const formattedLanguage = language.toUpperCase();

    function onHeaderButtonClickHandler(url) {
        openExternalLink(url);
    }

    useEffect(() => {
        async function fetchData() {
            const [
                header,
                whyGivTrade,
                steps,
                benefits
            ] = await Promise.all([
                getOffersHeader(),
                getOffersWhyGivTrade(),
                getOffersSteps(),
                getOffersBenefits()
            ]);

            const headerData = header.data.data[0];
            const whyGivTradeData = whyGivTrade.data.data[0];
            const stepsData= steps.data.data[0];
            const benefitsData= benefits.data.data[0];

            setContent({
                header: headerData,
                whyGivTrade: whyGivTradeData,
                steps: stepsData,
                benefits: benefitsData
            });

            setLoading(false);
        }

        fetchData();
    }, []);

    if(loading === true) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <div>
            {content.header && (
                <Header
                    title={content.header[`title${formattedLanguage}`]}
                    subtitle={content.header[`subtitle${formattedLanguage}`]}
                    secondTitle={content.header[`secondTitle${formattedLanguage}`]}
                    sourcePath={`${content.header.image.url}`}
                    buttonTitle={content.header[`buttonTitle${formattedLanguage}`]}
                    buttonOnClick={() => onHeaderButtonClickHandler(content.header.buttonLink)}
                />
            )}

            <OffersWhyGivTrade content={content.whyGivTrade} language={language} />

            <OffersSteps content={content.steps} language={formattedLanguage} />

            <OffersBenefits content={content.benefits} language={language} />
        </div>
    );
}

export default Offers;