import {MEDIA_BASE_URL} from "../constants/API";

export function downloadDocument(filePath, filename) {
    const link = document.createElement('a');
    link.href = `${MEDIA_BASE_URL}${filePath}`;
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
    link.download = filename || 'download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}