import '../../../App.css';
import './AffiliateProgramIntro.css';
import useVisibility from "../../../hooks/useVisibility";
import AffiliateProgramIntroService from "./affiliate-program-intro-service/AffiliateProgramIntroService";

function AffiliateProgramIntro({content, language}) {
    const [isVisible, containerRef] = useVisibility();

    const formattedLanguage = language.toUpperCase();

    return (
        <>
            {content && (
                <div
                    className={`component-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container'>
                        <h3 className='affiliate-program-intro-title'>{content[`title${formattedLanguage}`]}</h3>

                        <div className='affiliate-program-intro-caracs-container'>
                            {content[`caracs${formattedLanguage}`].map((carac, index) => (
                                <div key={index} className='affiliate-program-intro-carac-container'>
                                    <img src={`${content.pointerImage.url}`} alt='Carac Pointer'/>
                                    <div className='affiliate-program-intro-carac-text body-one'>{carac}</div>
                                </div>
                            ))}
                        </div>

                        {/*<div className='affiliate-program-intro-separator'></div>*/}

                        <div className='affiliate-program-intro-services-container'>
                            {content.services.map((service, index) => (
                                <AffiliateProgramIntroService
                                    key={index}
                                    service={service}
                                    language={language}
                                />
                            ))}
                        </div>

                        {/*<div className='affiliate-program-intro-vertical-separator'></div>*/}
                    </div>
                </div>
            )}
        </>
    );
}

export default AffiliateProgramIntro;