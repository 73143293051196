import '../../../App.css';
import './WhyGivtrade.css';
import ComponentTitle from "../../ui/ComponentTitle/ComponentTitle";
import CountUp from "react-countup";
import useVisibility from "../../../hooks/useVisibility";
import {MEDIA_BASE_URL} from "../../../constants/API";

function WhyGivtrade({whyGivTrade, language}) {
    const [isVisible, containerRef] = useVisibility();

    const formattedLanguage = language.charAt(0).toUpperCase() + language.slice(1);

    return (
        <>
            {whyGivTrade && whyGivTrade.length > 0 && (
                <div
                    style={{
                        backgroundImage: `url(${whyGivTrade[0].backgroundImage.url})`,
                    }}
                    className={`component-container why-givtrade-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}>
                    <div className='component-data-container why-givtrade-data-container'>
                        {/*<div className='why-givtrade-data-text-container'>*/}
                        {/*    <div className='why-givtrade-title'>*/}
                        {/*        <ComponentTitle*/}
                        {/*            titlePartOne={whyGivTrade[0].titlePartOne}*/}
                        {/*            titlePartTwo={whyGivTrade[0].titlePartTwo}*/}
                        {/*        />*/}
                        {/*    </div>*/}

                        {/*    <div className='why-givtrade-title-mobile'>*/}
                        {/*        <ComponentTitle titlePartOne='Why' titlePartTwo='GivTrade'/>*/}
                        {/*        <div className="why-givtrade-image-container-mobile">*/}
                        {/*            <video className='why-givtrade-image-mobile' autoPlay muted loop>*/}
                        {/*                <source*/}
                        {/*                    src={`${whyGivTrade[0].mainImageMobile.url}`}*/}
                        {/*                    type='video/mp4'*/}
                        {/*                />*/}
                        {/*                Your browser does not support the video tag.*/}
                        {/*            </video>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                            {/*{whyGivTrade[0].descriptions.map((description, index) => (*/}
                            {/*    <div key={index} className='why-givtrade-text body-one'>*/}
                            {/*        <span className='why-givtrade-text-bold'>{description.title}</span>*/}
                            {/*        {description.description}*/}
                            {/*    </div>*/}
                            {/*))}*/}

                            {/*<div className='why-givtrade-data-container-caracs'>*/}
                            {/*    {whyGivTrade[0].carac.map((c, index) => (*/}
                            {/*        <div key={index} className='why-givtrade-carac'>*/}
                            {/*            <video className='why-givtrade-carac-icon' autoPlay muted loop playsInline>*/}
                            {/*                <source src={`${MEDIA_BASE_URL}${c.imageUrl}`} type='video/mp4' />*/}
                            {/*                Your browser does not support the video tag.*/}
                            {/*            </video>*/}

                            {/*            <div className='subtitle why-givtrade-carac-text'>*/}
                            {/*                <span className='why-givtrade-carac-number'>*/}
                            {/*                    {isVisible && <CountUp start={0} end={c.number} duration={1.5}/>}+*/}
                            {/*                </span>*/}
                            {/*                {c.title}*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    ))}*/}

                                {/*{whyGivTrade[0].caracImages.map((c, index) => (*/}
                                {/*    <div key={index} className='why-givtrade-carac'>*/}
                                {/*        <video className='why-givtrade-carac-icon' autoPlay muted loop playsInline>*/}
                                {/*            <source src={`${MEDIA_BASE_URL}${c.imageUrl}`} type='video/mp4'/>*/}
                                {/*            Your browser does not support the video tag.*/}
                                {/*        </video>*/}

                                {/*        <div className='subtitle why-givtrade-carac-text'>{c.title}</div>*/}
                                {/*    </div>*/}
                                {/*))}*/}

                        {/*        <div className='why-givtrade-carac-image-container'>*/}
                        {/*            <img*/}
                        {/*                className='why-givtrade-carac-image'*/}
                        {/*                src={`${whyGivTrade[0].highlightImage.url}`}*/}
                        {/*                alt='Graph Highlight'/>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className='why-givtrade-data-image-container'>*/}
                        {/*    <video className='why-givtrade-image-web' autoPlay muted loop>*/}
                        {/*        <source src={`${whyGivTrade[0].mainImage.url}`} type='video/mp4' />*/}
                        {/*        Your browser does not support the video tag.*/}
                        {/*    </video>*/}
                        {/*</div>*/}

                        <div>
                            <div className='why-givtrade-title'>
                                <ComponentTitle
                                    titlePartOne={whyGivTrade[0][`titlePartOne${formattedLanguage}`]}
                                    titlePartTwo={whyGivTrade[0][`titlePartTwo${formattedLanguage}`]}
                                    titlePartOneColor='white'
                                />
                            </div>

                            <div className='why-givtrade-data-container-caracs'>
                                {whyGivTrade[0].carac.map((c, index) => (
                                    <div key={index} className='why-givtrade-carac'>
                                        <video className='why-givtrade-carac-icon' autoPlay muted loop playsInline>
                                            <source src={`${MEDIA_BASE_URL}${c.imageUrl}`} type='video/mp4' />
                                            Your browser does not support the video tag.
                                        </video>

                                        <div className='subtitle why-givtrade-carac-text'>
                                            <span className='why-givtrade-carac-number'>
                                                {isVisible && <CountUp start={0} end={c.number} duration={1.5}/>}+
                                            </span>
                                            {c.title}
                                        </div>
                                    </div>
                                ))}

                                {/*{whyGivTrade[0].caracImages.map((c, index) => (*/}
                                {/*    <div key={index} className='why-givtrade-carac'>*/}
                                {/*        <video className='why-givtrade-carac-icon' autoPlay muted loop playsInline>*/}
                                {/*            <source src={`${MEDIA_BASE_URL}${c.imageUrl}`} type='video/mp4'/>*/}
                                {/*            Your browser does not support the video tag.*/}
                                {/*        </video>*/}

                                {/*        <div className='subtitle why-givtrade-carac-text'>{c.title}</div>*/}
                                {/*    </div>*/}
                                {/*))}*/}

                                {whyGivTrade[0].caracImages.map((c, index) => (
                                    <div key={index} className='why-givtrade-carac'>
                                        <img
                                            className='why-givtrade-carac-icon'
                                            src={`${MEDIA_BASE_URL}${c.imageUrl}`}
                                            alt={c[`title-${language}`]}
                                        />

                                        {/*<div className='why-givtrade-separator'></div>*/}

                                        <div className='subtitle why-givtrade-carac-text'>{c[`title-${language}`]}</div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                {whyGivTrade[0].descriptions.map((description, index) => (
                                    <div key={index} className='why-givtrade-text body-one'>
                                        <span className='why-givtrade-text-bold'>{description.title}</span>
                                        {description.description}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default WhyGivtrade;