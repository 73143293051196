import '../../../../App.css';
import './HelpCenterQuestion.css';
import {useRef, useState} from "react";

function HelpCenterQuestion({question, answer}) {
    const [isOpen, setIsOpen] = useState(false);
    const answerContainerRef = useRef(null);

    function toggleOpen() {
        const answerContainer = answerContainerRef.current;

        if (!isOpen) {
            // Set to actual height of content on open
            answerContainer.style.maxHeight = `${answerContainer.scrollHeight}px`;
        } else {
            // Collapse by setting height to 0
            answerContainer.style.maxHeight = '0';
        }

        setIsOpen(!isOpen);
    }

    return (
        <div className='help-center-question-container'>
            <div className='help-center-question-header'>
                <div className='help-center-question-header-text'>{question}</div>
                <div className='help-center-question-icon-container'>
                    <div
                        className={`help-center-question-icon-inner-container ${
                            isOpen && 'help-center-question-icon-inner-container-open'
                        }`}
                        onClick={toggleOpen}
                    >
                        {isOpen ? '-' : '+'}
                    </div>
                </div>
                <div className='help-center-question-empty-container'></div>
            </div>

            <div
                className={`help-center-answer-container ${isOpen ? 'show' : ''}`}
                ref={answerContainerRef}
                style={{maxHeight: '0'}}
            >
                <div className='help-answer-container-border'></div>
                <p>{answer}</p>
            </div>
        </div>
    );
}

export default HelpCenterQuestion;