import {useEffect, useState} from "react";
import {
    getPlatformsCard, getPlatformsFeatures,
    getPlatformsHeader,
    getPlatformsIntro,
    getPlatformsSource
} from "../../services/PlatformsService";
import Header from "../../components/ui/header/Header";
import OurAppIntro from "../../components/platforms/our-app-intro/OurAppIntro";
import OurAppCard from "../../components/platforms/our-app-card/OurAppCard";
import OurAppSource from "../../components/platforms/our-app-source/OurAppSource";
import OurAppFeatures from "../../components/platforms/our-app-features/OurAppFeatures";
import LoadingSpinner from "../../components/ui/loading-spinner/LoadingSpinner";

function Platforms({language}) {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        async function fetchData() {
            try {
                const [
                    header,
                    intro,
                    ourAppCard,
                    source,
                    features
                ] = await Promise.all([
                    getPlatformsHeader(),
                    getPlatformsIntro(),
                    getPlatformsCard(),
                    getPlatformsSource(),
                    getPlatformsFeatures()
                ]);

                const headerData = header.data.data[0];
                const introData = intro.data.data[0];
                const ourAppCardData = ourAppCard.data.data[0];
                const sourceData = source.data.data[0];
                const featuresData = features.data.data[0];

                setContent({
                    header: headerData,
                    intro: introData,
                    ourAppCard: ourAppCardData,
                    source: sourceData,
                    features: featuresData,
                });

                setLoading(false);
            } catch (error) {}
        }

        fetchData();
    }, []);

    if(loading === true) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <>
            {content.header && (
                <div>
                    <Header
                        title={content.header[`title${formattedLanguage}`]}
                        subtitle={content.header[`subtitle${formattedLanguage}`]}
                        sourcePath={`${content.header.image.url}`}
                    />

                    <OurAppIntro intro={content.intro} language={formattedLanguage} />

                    <OurAppCard content={content.ourAppCard} language={formattedLanguage} />

                    <OurAppSource content={content.source} language={formattedLanguage} />

                    <OurAppFeatures content={content.features} language={formattedLanguage} />
                </div>
            )}
        </>
    );
}

export default Platforms;