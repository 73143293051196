import '../../../App.css';
import './BorderButton.css';

function BorderButton({ title, onClick, hasWhiteBorder, hasFilledBorder }) {
    return (
        <div
            className={`button border-button card ${hasWhiteBorder === true && 'border-button-solid'} ${hasFilledBorder === true && 'border-button-filled'}`}
            onClick={onClick}
        >
            {title}
        </div>
    );
}

export default BorderButton;