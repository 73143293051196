import '../../../../App.css';
import './AccountType.css';
import CustomButton from "../../../ui/CustomButton/CustomButton";
import {MEDIA_BASE_URL} from "../../../../constants/API";

function AccountType({ accountType, language }) {

    return (
        <div className='account-type-card background-color-gradient card box-shadow-all-sides scale-up-hover'>
            <div className='account-type-card-title'>{accountType[`title${language}`]}</div>

            <div className='account-type-card-description-container'>
                {accountType[`descriptions${language}`].map((description, index) =>
                    <div key={index} className='account-type-description'>
                        <img src={`${MEDIA_BASE_URL}/correct_sign_678491f348.svg`} alt='Correct Sign'/>
                        <div className='account-type-card-description body-two'>{description}</div>
                    </div>
                )}
            </div>

            <div className='account-type-card-button-container'>
                <CustomButton
                    title={accountType[`buttonTitle${language}`]}
                    onClick={accountType.onClick}
                    hasNoGradient={true}
                />
            </div>
        </div>
    );
}

export default AccountType;