import '../../App.css';
import Header from "../../components/ui/header/Header";
import Services from "../../components/accounts/services/Services";
import AccountTypes from "../../components/accounts/account-types/AccountTypes";
import {useEffect, useState} from "react";
import {getAccountsHeader, getAccountsServices, getAccountsTypes} from "../../services/AccountsService";
import LoadingSpinner from "../../components/ui/loading-spinner/LoadingSpinner";

function Accounts({language}) {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        async function fetchData() {
            try {
                const [
                    header,
                    service,
                    accountType
                ] = await Promise.all([
                    getAccountsHeader(),
                    getAccountsServices(),
                    getAccountsTypes()
                ]);

                const headerData = header.data.data[0];

                const serviceData = service.data.data[0];

                const accountTypeData = accountType.data.data[0];

                setContent({
                    header: headerData,
                    service: serviceData,
                    accountType: accountTypeData
                });

                setLoading(false);
            } catch (error) {}
        }

        fetchData();
    }, []);

    if(loading === true) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <div>
            {content.header && (
                <Header
                    title={content.header[`title${formattedLanguage}`]}
                    subtitle={content.header[`subtitle${formattedLanguage}`]}
                    sourcePath={`${content.header.image.url}`}
                />
            )}

            <Services service={content.service} language={formattedLanguage} />

            <AccountTypes accountType={content.accountType} language={language} />
        </div>
    );
}

export default Accounts;