import '../../../App.css';
import './OffersSteps.css';
import useVisibility from "../../../hooks/useVisibility";
import CustomButton from "../../ui/CustomButton/CustomButton";
import {openExternalLink} from "../../../services/AuthService";

function OffersSteps({content, language}) {
    const [isVisible, containerRef] = useVisibility();

    function onButtonClickHandler() {
        openExternalLink(content.buttonLink);
    }

    return (
        <>
            {content && (
                <div
                    className={`component-container offers-steps-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container'>
                        <h2 className='offers-steps-title'>
                            {content[`title${language}`]}
                        </h2>

                        <h3 className='offers-steps-subtitle'>
                            {content[`subtitle${language}`]}
                        </h3>

                        <div className='offers-steps-container'>
                            {content[`steps${language}`].map((step, index) => (
                                <>
                                    <div key={index} className='offers-step-container background-color-gradient card'>
                                        <div className='offers-step'>{step}</div>
                                    </div>
                                    {index !== content[`steps${language}`].length - 1 && (
                                        <>
                                            <img
                                                className='for-web'
                                                src={`${content.separatorImage.url}`}
                                                alt='separator'
                                            />

                                            <img
                                                className='offers-steps-image for-mobile'
                                                src={`${content.separatorMobileImage.url}`}
                                                alt='separator'
                                            />
                                        </>
                                    )}
                                </>
                            ))}
                        </div>

                        <div className='offers-steps-button-container'>
                            <CustomButton
                                title={content[`buttonTitle${language}`]}
                                onClick={() => onButtonClickHandler()}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default OffersSteps;