export function openEmail() {
    window.open('mailto:info@givtrade.com');
}

export function openEmailTo(email) {
    window.open(`mailto:${email}`);
}

export function openLink(url) {
    window.location.href = url;
}

export function openExternalLink(url) {
    window.open(url, '_blank');
}