import ComponentTitle from "../../ui/ComponentTitle/ComponentTitle";
import '../../../App.css';
import './TradingPlatforms.css';
import useVisibility from "../../../hooks/useVisibility";
import {MEDIA_BASE_URL} from "../../../constants/API";
import {openExternalLink} from "../../../services/AuthService";

function TradingPlatforms({tradingPlatforms, language}) {
    const [isVisible, containerRef] = useVisibility();

    const formattedLanguage = language.toUpperCase();

    function isMetatrader(applicationUrl) {
        return applicationUrl.includes('Metatrader');
    }

    function onApplicationClickHandler(url) {
        openExternalLink(url);
    }

    return (
        <>
            {tradingPlatforms.length > 0 && (
                <div
                    style={{
                        backgroundImage: `url(${tradingPlatforms[0].backgroundImage.url})`,
                    }}
                    className={`component-container trading-platforms-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    {/* for web */}
                    <div
                        className='component-data-container trading-platforms-data-container trading-platforms-data-container-for-web'>
                        <div className='trading-platforms-data'>
                            <ComponentTitle
                                titlePartOne={tradingPlatforms[0][`titlePartOne${formattedLanguage}`]}
                                titlePartTwo={tradingPlatforms[0][`titlePartTwo${formattedLanguage}`]}
                                titlePartOneColor='white'
                            />

                            <div className='trading-platforms-description body-one'>
                                {tradingPlatforms[0][`descriptionPartOne${formattedLanguage}`]}
                            </div>
                            <div className='trading-platforms-description trading-platforms-description-weight body-one'>
                                {tradingPlatforms[0][`descriptionPartTwo${formattedLanguage}`]}
                            </div>

                            <div className='trading-platforms-app-buttons-container'>
                                {tradingPlatforms[0].applications.map((app, index) => (
                                    <div
                                        key={index}
                                        className={`trading-platforms-button trading-platforms-border ${isMetatrader(app.alt) && 'trading-platforms-metatrader'}`}
                                        onClick={() => onApplicationClickHandler(app.link)}
                                    >
                                        <img src={`${MEDIA_BASE_URL}${app.imageUrl}`} alt={app.alt} />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div>
                            {/*{tradingPlatforms[0].sideText.map((text, index) => (*/}
                            {/*    <div key={index} className='trading-platforms-image-text trading-platforms-border'>*/}
                            {/*        <div className='trading-platforms-image-text-one'>{text.title}</div>*/}
                            {/*        <div>{text.subtitle}</div>*/}
                            {/*    </div>*/}
                            {/*))}*/}

                            <div className='trading-platforms-image-container'>
                                <img
                                    className='trading-platforms-image'
                                    src={`${tradingPlatforms[0].mainImage.url}`}
                                    alt='Guy With Phone'
                                />
                            </div>
                        </div>
                    </div>

                    {/* for mobile */}
                    <div className='component-data-container trading-platforms-data-container-for-mobile'>
                        <ComponentTitle
                            titlePartOne={tradingPlatforms[0].titlePartOne}
                            titlePartTwo={tradingPlatforms[0].titlePartTwo}
                            titlePartOneColor='white'
                        />

                        <div className='trading-platforms-description trading-platforms-description-mobile body-one'>
                            {tradingPlatforms[0].descriptionPartOne}
                        </div>
                        <div className='trading-platforms-description trading-platforms-description-weight body-one'>
                            {tradingPlatforms[0].descriptionPartTwo}
                        </div>

                        <div className='trading-platforms-data-container trading-platforms-buttons-image-container'>
                            <div className='trading-platforms-buttons-container-mobile'>
                                {tradingPlatforms[0].applicationsMobile.map((app, index) => (
                                    <div
                                        key={index}
                                        className='trading-platforms-button trading-platforms-border'
                                        onClick={() => onApplicationClickHandler(app.link)}
                                    >
                                        <img src={`${MEDIA_BASE_URL}${app.imageUrl}`} alt={app.alt} />
                                    </div>
                                ))}
                            </div>

                            <div>
                                {/*{tradingPlatforms[0].sideText.map((text, index) => (*/}
                                {/*    <div key={index} className='trading-platforms-image-text trading-platforms-border'>*/}
                                {/*        <div className='trading-platforms-image-text-one'>{text.title}</div>*/}
                                {/*        <div>{text.subtitle}</div>*/}
                                {/*    </div>*/}
                                {/*))}*/}

                                <div className='trading-platforms-image-container'>
                                    <img className='trading-platforms-image' src={`${tradingPlatforms[0].mainImage.url}`}
                                         alt='Guy With Phone'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default TradingPlatforms;