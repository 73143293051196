import '../../../../../App.css';
import './JobForm.css';
import {useCallback, useState} from "react";
import CustomInput from "../../../../ui/custom-input/CustomInput";
import CustomButton from "../../../../ui/CustomButton/CustomButton";
import FileUploader from "../../../../ui/file-uploader/FileUploader";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import LoadingSpinner from "../../../../ui/loading-spinner/LoadingSpinner";
import {EMAIL_SERVER_BASE_URL} from "../../../../../constants/API";

function JobForm({ jobId }) {
    const [fullName, setFullName] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postal, setPostal] = useState('');
    const [phone, setPhone] = useState('');
    const [resumeFile, setResumeFile] = useState(null);

    const [clearFile, setClearFile] = useState(() => () => {});

    const [isLoading, setIsLoading] = useState(false);

    const [errors, setErrors] = useState({
        fullNameError: '',
        addressError: '',
        emailError: '',
        cityError: '',
        stateError: '',
        postalError: '',
        phoneError: '',
        resumeFileError: '',
    });

    const navigate = useNavigate();

    const handleSetClearFile = useCallback((clearFunc) => {
        setClearFile(() => clearFunc); // Only updates once the function is set.
    }, []);

    function onFullNameChangedHandler(valueObject) {
        setFullName(valueObject.value);
    }

    function onAddressChangedHandler(valueObject) {
        setAddress(valueObject.value);
    }

    function onEmailChangedHandler(valueObject) {
        setEmail(valueObject.value);
    }

    function onCityChangedHandler(valueObject) {
        setCity(valueObject.value);
    }

    function onStateChangedHandler(valueObject) {
        setState(valueObject.value);
    }

    function onPostalChangedHandler(valueObject) {
        setPostal(valueObject.value);
    }

    function onPhoneChangedHandler(valueObject) {
        setPhone(valueObject.value);
    }

    function onUploadFileHandler(valueObject) {
        setResumeFile(valueObject.file);
    }

    function onSubmitApplicationHandler() {
        setIsLoading(true);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const fullNameEmpty = fullName == null || fullName === '';
        const addressEmpty = address == null || address === '';
        const emailEmpty = email == null || email === '';
        const cityEmpty = city == null || city === '';
        const stateEmpty = state == null || state === '';
        const postalEmpty = postal == null || postal === '';
        const phoneEmpty = phone == null || phone === '';
        const resumeFileEmpty = resumeFile == null;

        if(fullNameEmpty || addressEmpty || emailEmpty || cityEmpty || stateEmpty || postalEmpty || phoneEmpty || resumeFileEmpty) {
            setErrors(prevErrors => ({
                ...prevErrors,
                fullNameError: fullNameEmpty && 'Fill all fields',
                addressError: addressEmpty && 'Fill all fields',
                emailError: emailEmpty && 'Fill all fields',
                cityError: cityEmpty && 'Fill all fields',
                stateError: stateEmpty && 'Fill all fields',
                postalError: postalEmpty && 'Fill all fields',
                phoneError: phoneEmpty && 'Fill all fields',
                resumeFileError: resumeFileEmpty && 'Upload a resume'
            }));

            return;
        }

        if(!emailEmpty && !emailRegex.test(email)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                emailError: 'Enter a valid email address'
            }));

            return;
        }

        // Prepare the form data
        const formData = new FormData();
        formData.append('fullName', fullName);
        formData.append('address', address);
        formData.append('email', email);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('postal', postal);
        formData.append('phone', phone);
        formData.append('resume', resumeFile);

        // Post to backend
        axios
            .post(`${EMAIL_SERVER_BASE_URL}/job-application-email`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((response) => {
                setIsLoading(false);
                alert('Application sent successfully!');
                clearFile();
                navigate('/');
            })
            .catch((error) => {
                setIsLoading(false);
                alert('Error sending application. Try again later.');
                console.error('Error:', error);
            });

        setFullName('');
        setAddress('');
        setEmail('');
        setCity('');
        setState('');
        setPostal('');
        setPhone('');
        setResumeFile(null);
    }

    if(isLoading === true) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <div className='component-container'>
            <div className='component-data-container'>
                <div className='careers-jobs-form-container'>
                    <div className='careers-jobs-form-item'>
                        <CustomInput
                            title='Full Name'
                            type='text'
                            value={fullName}
                            onChange={onFullNameChangedHandler}
                            error={errors.fullNameError}
                        />
                    </div>

                    <div className='careers-jobs-form-item'>
                        <CustomInput
                            title='Address'
                            type='text'
                            value={address}
                            onChange={onAddressChangedHandler}
                            error={errors.addressError}
                        />
                    </div>

                    <div className='careers-jobs-form-item'>
                        <CustomInput
                            title='Email'
                            type='text'
                            value={email}
                            onChange={onEmailChangedHandler}
                            error={errors.emailError}
                        />
                    </div>

                    <div className='careers-jobs-form-item careers-jobs-form-item-container'>
                        <div className='careers-jobs-form-item-smaller'>
                            <CustomInput
                                title='City'
                                type='text'
                                value={city}
                                onChange={onCityChangedHandler}
                                error={errors.cityError}
                            />
                        </div>

                        <div className='careers-jobs-form-item-smaller'>
                            <CustomInput
                                title='State'
                                type='text'
                                value={state}
                                onChange={onStateChangedHandler}
                                error={errors.stateError}
                            />
                        </div>
                    </div>

                    <div className='careers-jobs-form-item'>
                        <CustomInput
                            title='Postal'
                            type='number'
                            value={postal}
                            onChange={onPostalChangedHandler}
                            error={errors.postalError}
                        />
                    </div>

                    <div className='careers-jobs-form-item'>
                        <CustomInput
                            title='Phone'
                            type='number'
                            value={phone}
                            onChange={onPhoneChangedHandler}
                            error={errors.phoneError}
                        />
                    </div>

                    <div className='careers-jobs-form-item-larger'>
                        <FileUploader
                            onUploadFile={onUploadFileHandler}
                            error={errors.resumeFileError}
                            setClearFile={handleSetClearFile}
                        />
                    </div>
                </div>

                <div className='careers-jobs-form-submit-container'>
                    <CustomButton title='SUBMIT APPLICATION' onClick={onSubmitApplicationHandler}/>
                </div>
            </div>
        </div>
    );
}

export default JobForm;