import axios from "axios";
import {BASE_URL} from "../../constants/API";

export async function getForexHeader() {
    return await axios.get(BASE_URL + '/markets-forex-headers?populate=image');
}

export async function getForexDefinition() {
    return await axios.get(BASE_URL + '/markets-forex-definitions');
}

export async function getForexDataContent() {
    return await axios.get(BASE_URL + '/markets-forex-data-contents');
}