import '../../../App.css';
import './ComponentTitle.css';

function ComponentTitle({titlePartOne, titlePartTwo, titlePartOneColor}) {
    const partOneColor = titlePartOneColor ? titlePartOneColor : 'black';

    return (
        <h2>
            <span style={{ color: partOneColor }}>{titlePartOne}</span>
            <span className='title-part-two'> {titlePartTwo}</span>
        </h2>
    );
}

export default ComponentTitle;