import '../../../App.css';
import './NavbarCredential.css';

function NavbarCredential({ title, source, onClick }) {
    return (
        <div className='credential-container' onClick={onClick}>
            {/*<img className='navbar-icon' src={source} alt={title} />*/}
            <div className='button credential-button'>{title}</div>
        </div>
    );
}

export default NavbarCredential;