import '../../../App.css';
import './OurAppFeatures.css';
import useVisibility from "../../../hooks/useVisibility";
import {openExternalLink} from "../../../services/AuthService";

function OurAppFeatures({content, language}) {
    const [isVisible, containerRef] = useVisibility();

    function onSocialMediaClickHandler(url) {
        openExternalLink(url);
    }

    return (
        <>
            {content && (
                <div
                    className={`component-container our-app-features-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container'>
                        <h3 className='our-app-features-title'>{content[`title${language}`]}</h3>

                        {/* for web */}
                        <div className='for-web'>
                            <div className='our-app-features-mobile-container for-web'>
                                <div className='our-app-features-list our-app-features-left'>
                                    {content[`leftFeatures${language}`].map((feature, index) => (
                                        <div key={index} className='our-app-features-item-container'>
                                            <img src={`${content.pointerImage.url}`} alt='Pointer' />
                                            <div className='our-app-features-item'>{feature}</div>
                                        </div>
                                    ))}
                                </div>

                                <div className='our-app-features-image-container'>
                                    <img
                                        className='our-app-features-store-icon our-app-features-store-icon-left'
                                        src={`${content.appStoreImage.url}`}
                                        alt='Store Icon'
                                    />

                                    <img
                                        className='our-app-features-main-image'
                                        src={`${content.mainImage.url}`}
                                        alt='Mobile Phone'
                                        onClick={() => onSocialMediaClickHandler(content.appStoreLink)}
                                    />

                                    <img
                                        className='our-app-features-store-icon our-app-features-store-icon-right'
                                        src={`${content.playStoreImage.url}`}
                                        alt='Store Icon'
                                        onClick={() => onSocialMediaClickHandler(content.playStoreLink)}
                                    />
                                </div>

                                <div className='our-app-features-list our-app-features-right'>
                                    {content[`rightFeatures${language}`].map((feature, index) => (
                                        <div key={index} className='our-app-features-item-container'>
                                            <img src={`${content.pointerImage.url}`} alt='Pointer' />
                                            <div className='our-app-features-item'>{feature}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* for mobile */}
                        <div className='for-mobile our-app-features-data-container'>
                            <img
                                src={`${content.mainImageMobile.url}`}
                                alt='Mobile Phone'
                            />

                            <div className='our-app-features-list'>
                                {content[`leftFeatures${language}`].map((feature, index) => (
                                    <div key={index} className='our-app-features-item-container'>
                                        <img src={`${content.pointerImage.url}`} alt='Pointer' />
                                        <div className='our-app-features-item'>{feature}</div>
                                    </div>
                                ))}

                                {content[`rightFeatures${language}`].map((feature, index) => (
                                    <div key={index} className='our-app-features-item-container'>
                                        <img src={`${content.pointerImage.url}`} alt='Pointer' />
                                        <div className='our-app-features-item'>{feature}</div>
                                    </div>
                                ))}
                            </div>

                            <div className='our-app-features-image-container'>
                                <img
                                    className='our-app-features-store-icon'
                                    src={`${content.appStoreImage.url}`}
                                    alt='Store Icon'
                                    onClick={() => onSocialMediaClickHandler(content.appStoreLink)}
                                />

                                <img
                                    className='our-app-features-store-icon'
                                    src={`${content.playStoreImage.url}`}
                                    alt='Store Icon'
                                    onClick={() => onSocialMediaClickHandler(content.playStoreLink)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default OurAppFeatures;