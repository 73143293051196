import { useState, useEffect, useRef } from "react";

/**
 * Custom hook to observe when an element enters the viewport.
 * @param {Object} options Intersection Observer options (e.g., { threshold: 0.3 }).
 * @returns {Array} A tuple: [isVisible, ref]
 */
const useVisibility = (options = { threshold: 0.3 }) => {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            options
        );

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(elementRef.current);
            }
        };
    }, [options]);

    return [isVisible, elementRef];
};

export default useVisibility;