import axios from "axios";
import {BASE_URL} from "../constants/API";

export async function getPlatformsHeader() {
    return await axios.get(BASE_URL + '/platforms-headers?populate=image');
}

export async function getPlatformsIntro() {
    return await axios.get(BASE_URL + '/platforms-intros');
}

export async function getPlatformsCard() {
    return await axios.get(BASE_URL + '/platforms-cards?populate=image');
}

export async function getPlatformsSource() {
    return await axios.get(BASE_URL + '/platforms-sources?populate=image&&populate=separatorImage&&populate=separatorImageMobile');
}

export async function getPlatformsFeatures() {
    return await axios.get(BASE_URL + '/platforms-features?populate=pointerImage&&populate=mainImage&&populate=mainImageMobile&&populate=appStoreImage&&populate=playStoreImage');
}