import './SplashScreen.css';
import {MEDIA_BASE_URL} from "../../../constants/API";
import {useState} from "react";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";

function SplashScreen({onVideoEnd}) {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    function handleVideoLoad() {
        setIsVideoLoaded(true);
    }

    return (
        <div className='splash-screen-container'>
            {isVideoLoaded === false && <LoadingSpinner />}
            <video
                className='splash-screen-video'
                autoPlay muted playsInline
                preload='auto'
                onEnded={onVideoEnd}
                style={{display: isVideoLoaded ? "block" : "none"}}
                onLoadedData={handleVideoLoad}
            >
                <source src={`${MEDIA_BASE_URL}/splash_screen_new_9826b5dfb8.mp4`} type='video/mp4' />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export default SplashScreen;