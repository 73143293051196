import '../../../App.css';
import './AccountTypes.css';
import AccountType from "./account-type/AccountType";
import CustomButton from "../../ui/CustomButton/CustomButton";
import {useNavigate} from "react-router-dom";
import useVisibility from "../../../hooks/useVisibility";
import AccountTypeForm from "./account-type-form/AccountTypeForm";
import {useEffect, useState} from "react";
import {openExternalLink} from "../../../services/AuthService";

function AccountTypes({accountType, language}) {
    const [showForm, setShowForm] = useState(false);
    const [accountTypes, setAccountTypes] = useState([]);
    const [accountTypeId, setAccountTypeId] = useState(null);

    const [isVisible, containerRef] = useVisibility();
    const navigate = useNavigate();

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        setAccountTypes(accountType.accountTypes);
    }, [accountType]);

    function openForm(accountTypeId) {
        setShowForm(true);
        setAccountTypeId(accountTypeId);
    }

    function closeForm() {
        setShowForm(false);
        setAccountTypeId(null);
    }

    function onButtonClickHandler() {
        openExternalLink(accountType.buttonExternalLink);
    }

    return (
        <>
            {accountType && (
                <div
                    className={`component-container-no-full-height ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container'>
                        {!showForm && (
                            <div>
                                <h2 className='account-types-header'>{accountType[`title${formattedLanguage}`]}</h2>

                                <div className='account-types-container'>
                                    {accountTypes.map(type => (
                                        <AccountType
                                            key={type.id}
                                            accountType={type}
                                            accountTypes={accountType}
                                            onSelectType={() => openForm(type.id)}
                                            language={language}
                                        />
                                    ))}
                                </div>

                                <div className='account-types-button-container'>
                                    <CustomButton
                                        title={accountType[`buttonTitle${formattedLanguage}`]}
                                        onClick={() => onButtonClickHandler()}
                                    />
                                </div>
                            </div>
                        )}

                        {showForm && (
                            <AccountTypeForm
                                accountTypeId={accountTypeId}
                                onCancelForm={closeForm}
                                language={formattedLanguage}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default AccountTypes;