import '../../../App.css';
import './FileUploader.css';
import {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import CustomButton from "../CustomButton/CustomButton";
import UploadImage from '../../../assets/upload.svg';

function FileUploader({onUploadFile, error, setClearFile}) {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);

    const onDrop = useCallback((files) => {
        uploadFile(files[0]);
    }, [uploadFile]);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
        },
        maxFiles: 1
    });

    useEffect(() => {
        // Update parent's `setClearFile` only after rendering.
        if (setClearFile) {
            setClearFile(() => removeUploadedFile);
        }
    }, [removeUploadedFile, setClearFile]);

    function onHandleFileUpload(event) {
        const file = event.target.files[0];
        if (file) {
            uploadFile(file);
        }
    }

    function removeUploadedFile() {
        uploadFile(null);
    }

    function uploadFile(file) {
        setUploadedFile(file);
        setFileUrl(file ? URL.createObjectURL(file) : null);
        onUploadFile({file: file});
    }

    return (
        <div>
            {!uploadedFile && (
                <div className='file-uploader-container' {...getRootProps()}>
                    <input {...getInputProps()} onChange={onHandleFileUpload} />
                    <img className='file-uploader-icon' src={UploadImage} alt='Upload Resume' />
                    <div className='file-uploader-placeholder subtitle'>Drag & Drop or Choose file to upload</div>
                </div>
            )}

            {error && (
                <div className='form-error-container'>
                    <div className='form-error-icon'>!</div>
                    <div>{error}</div>
                </div>
            )}

            <div className='file-uploader-preview-container'>
                {uploadedFile && (
                    <div className='file-uploader-preview-container'>
                        <div>
                            <strong>Uploaded File:</strong>
                            {uploadedFile.name ? uploadedFile.name : uploadedFile.handle.name}
                        </div>

                        <div>
                            <strong>Preview:</strong>
                        </div>

                        {/* Render preview based on file type */}
                        {uploadedFile.type.startsWith('image/') && (
                            <img
                                src={fileUrl}
                                alt="Preview"
                                className='file-uploader-image'
                            />
                        )}

                        {uploadedFile.type === "application/pdf" && (
                            <iframe
                                src={fileUrl}
                                title="PDF Preview"
                                className='file-uploader-pdf'
                            />
                        )}

                        {uploadedFile.type.startsWith('text/') && (
                            <textarea readOnly className='file-uploader-text'>
                                {fileUrl}
                            </textarea>
                        )}

                        {/* Download option */}
                        <a
                            href={fileUrl}
                            download={uploadedFile.name ? uploadedFile.name : uploadedFile.handle.name}
                            className='file-uploader-download'
                        >
                            Download {uploadedFile.name ? uploadedFile.name : uploadedFile.handle.name}
                        </a>

                        <div className='file-uploader-remove-container'>
                            <CustomButton title='Remove File' onClick={removeUploadedFile} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default FileUploader;