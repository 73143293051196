import '../../../../App.css';
import './AccountType.css';
import CustomButton from "../../../ui/CustomButton/CustomButton";
import {openExternalLink} from "../../../../services/AuthService";

function AccountType({accountType, accountTypes, onSelectType, language}) {
    const formattedLanguage = language.toUpperCase();

    function onOvernightChargesHandler() {
        openExternalLink(accountTypes.overnightChargesDocument.url);
    }

    return (
        <div>
            <div className={`account-type-container background-color-gradient card box-shadow-all-sides scale-up-hover ${
                accountType.isBestChoice && 'account-types-best-choice-container'}`
            }>
                {accountType.isBestChoice && (
                    <div className='account-types-best-choice'>{accountTypes[`bestChoiceLabel${formattedLanguage}`]}</div>
                )}
                <h3 className='account-types-title'>{accountType[`title-${language}`]}</h3>

                <div className={`account-types-description-container ${(accountType[`descriptions-${language}`] && accountType[`descriptions-${language}`].length === 1) && 'account-types-description-container-one'}`} >
                    <h4>{accountType[`subtitle-${language}`]}</h4>

                    {accountType[`descriptions-${language}`].map((description, index) => (
                        <div key={index} className='account-types-description'>
                            <div className='body-two'>{description.descriptionTitle}</div>
                            <h4>{description.descriptionValue}</h4>
                        </div>
                    ))}

                    <div className='account-types-description-button-container'>
                        <CustomButton
                            title={accountTypes[`selectTypeButtonName${formattedLanguage}`]}
                            backgroundColor='var(--color-black)'
                            onClick={onSelectType}
                            hasNoGradient={true}
                        />
                    </div>
                </div>

                <div className='account-types-services-container'>
                    {accountType[`services-${language}`].map((service, index) => (
                        <div key={index} className='account-types-service'>
                            <img src={`${accountTypes.servicePointerImage.url}`} alt='Correct Sign'/>
                            <div className='account-types-service-text body-three'>{service}</div>
                        </div>
                    ))}
                </div>

                {accountType.hasOvernightCharges && (
                    <div className='account-types-overnight-charges-container'>
                        <div className='account-types-overnight-charges' onClick={onOvernightChargesHandler} >
                            {accountTypes.overnightChargesName}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AccountType;