import axios from "axios";
import {BASE_URL} from "../../constants/API";

export async function getWhoWeAreHeader() {
    return await axios.get(BASE_URL + '/who-we-are-headers?populate=image');
}

export async function getWhoWeAreDefinition() {
    return await axios.get(BASE_URL + '/who-we-are-definitions');
}

export async function getWhoWeAreMissionVision() {
    return await axios.get(BASE_URL + '/who-we-are-mission-visions?populate=image&populate=backgroundImage');
}

export async function getWhoWeArePrizes() {
    return await axios.get(BASE_URL + '/who-we-are-prizes?populate=mainImage&populate=mainImageMobile&populate=backgroundImage');
}

export async function getWhoWeAreValues() {
    return await axios.get(BASE_URL + '/who-we-are-values');
}

export async function getWhoWeAreMeetTeam() {
    return await axios.get(BASE_URL + '/who-we-are-meet-teams?populate=linkedInLogo');
}