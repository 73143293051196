import '../../../App.css';
import './AffiliateProgramJoin.css';
import {openExternalLink} from "../../../services/AuthService";
import CustomButton from "../../ui/CustomButton/CustomButton";
import useVisibility from "../../../hooks/useVisibility";

function AffiliateProgramJoin({content, language}) {
    const [isVisible, containerRef] = useVisibility();

    function onJoinTodayHandler() {
        openExternalLink(content.buttonLink);
    }

    return (
        <>
            {content && (
                <div
                    style={{
                        backgroundImage: `url(${content.backgroundImage.url})`,
                    }}
                    className={`component-container affiliate-program-join-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='affiliate-program-join-data-container component-data-container'>
                        {/*<div className='affiliate-program-join-vertical-separator'></div>*/}

                        <h3 className='affiliate-program-join-title'>
                            {content[`title${language}`]}
                        </h3>

                        <div className='affiliate-program-join-description body-one'>
                            {content[`description${language}`]}
                        </div>

                        {content[`buttonTitle${language}`] && (
                            <div className='affiliate-program-join-button-container'>
                                <CustomButton
                                    title={content[`buttonTitle${language}`]}
                                    onClick={onJoinTodayHandler}
                                    hasNoGradient={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default AffiliateProgramJoin;