import '../../../../App.css';
import './Culture.css';
import {MEDIA_BASE_URL} from "../../../../constants/API";

function Culture({culture, language}) {
    const formattedLanguage = language.toUpperCase();

    return (
        <>
            {culture && (
                <div className='component-container careers-culture-container'>
                    <div className='component-data-container'>
                        <h2 className='careers-culture-title'>{culture[`title${formattedLanguage}`]}</h2>

                        <div className='careers-culture-data-container'>
                            {culture.cultures.map((culture, index) => (
                                <div className='careers-culture-data' key={index}>
                                    <img
                                        className='careers-culture-data-image'
                                        src={`${MEDIA_BASE_URL}${culture.imagePath}`}
                                        alt={culture[`title-${language}`]}
                                    />
                                    <div className='careers-culture-data-title subtitle'>{culture[`title-${language}`]}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Culture;