import { useEffect } from "react";

const TidioChatbot = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//code.tidio.co/wdmc7uil1aalibnqn1xziwnidq6kj89i.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup: Remove the script when component unmounts
            document.body.removeChild(script);
        };
    }, []);

    return null;
};

export default TidioChatbot;