import '../../../App.css';
import './OffersBenefits.css';
import useVisibility from "../../../hooks/useVisibility";

function OffersBenefits({content, language}) {
    const [isVisible, containerRef] = useVisibility();

    const formattedLanguage = language.toUpperCase();

    return (
        <>
            {content && (
                <div
                    className={`component-container offers-benefits-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container offers-benefits-data-container'>
                        <div className='offers-benefits-text-container'>
                            <h2 className='offers-benefits-title'>
                                {content[`title${formattedLanguage}`]}
                            </h2>

                            <h3 className='offers-benefits-subtitle'>
                                {content[`subtitle${formattedLanguage}`]}
                            </h3>

                            <div className='offers-benefits'>
                                {content.benefits.map((benefit, index) => (
                                    <div key={index} className='offers-benefit card'>
                                        <div className='offers-benefit-text'>
                                            <div className='offers-benefit-title'>
                                                {benefit[`title-${language}`]}
                                            </div>

                                            <div className='offers-benefit-description'>
                                                {benefit[`description-${language}`]}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='offers-benefit-image-container'>
                            <img
                                className='offers-benefit-image'
                                src={`${content.image.url}`}
                                alt='Offers Benefits'
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default OffersBenefits;