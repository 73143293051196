import '../../../App.css';
import './FundingMethods.css';
import ComponentTitle from "../../ui/ComponentTitle/ComponentTitle";
import {useEffect, useState} from "react";
import CustomButton from "../../ui/CustomButton/CustomButton";
import useVisibility from "../../../hooks/useVisibility";
import {openExternalLink} from "../../../services/AuthService";
import {MEDIA_BASE_URL} from "../../../constants/API";

function FundingMethods({fundingMethods, language}) {
    const [isVisible, containerRef] = useVisibility();

    const [images, setImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        let interval;

        const fetchedImages = fundingMethods[0].images.map(image => `${MEDIA_BASE_URL}${image}`);
        setImages(fetchedImages);

        if (images.length > 0) {
            interval = setInterval(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 2000);
        }

        return () => clearInterval(interval);
    }, [fundingMethods, images.length]);

    function onOpenAccountHandler(url) {
        openExternalLink(url);
    }

    return (
        <>
            {fundingMethods.length > 0 && (
                <div
                    style={{
                        backgroundImage: `url(${fundingMethods[0].backgroundImage.url})`,
                    }}
                    className={`component-container funding-methods-initial-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container'>
                        <ComponentTitle
                            titlePartOne={fundingMethods[0][`titlePartOne${formattedLanguage}`]}
                            titlePartTwo={fundingMethods[0][`titlePartTwo${formattedLanguage}`]}
                            titlePartOneColor='white'
                        />

                        <div className='funding-methods-container'>
                            <div className='funding-methods-image-container for-mobile'>
                                <img
                                    key={currentImageIndex}
                                    className='funding-methods-image'
                                    src={images[currentImageIndex]}
                                    alt='Funding Methods'
                                />
                            </div>

                            {/*<div className='funding-methods-data-container'>*/}
                            {/*    <ComponentTitle*/}
                            {/*        titlePartOne={fundingMethods[0].titlePartOne}*/}
                            {/*        titlePartTwo={fundingMethods[0].titlePartTwo}*/}
                            {/*    />*/}

                            {/*    <div className='funding-methods-description body-one'>*/}
                            {/*        {fundingMethods[0].description}*/}
                            {/*    </div>*/}

                            {/*    <div className='funding-methods-steps-container'>*/}
                            {/*        {fundingMethods[0].steps.map((step, index) =>*/}
                            {/*            <div className='funding-method-step-container' key={index}>*/}
                            {/*                <h2 className='funding-methods-step-header'>STEP {index + 1}</h2>*/}
                            {/*                <div className='funding-methods-step-title'>{step.title}</div>*/}
                            {/*                <div className='funding-methods-step-subtitle'>{step.subtitle}</div>*/}
                            {/*                {step.buttonTitle && (*/}
                            {/*                    <div className='funding-methods-step-button'>*/}
                            {/*                        <CustomButton*/}
                            {/*                            title={step.buttonTitle}*/}
                            {/*                            onClick={() => onOpenAccountHandler(step.buttonLink)}*/}
                            {/*                        />*/}
                            {/*                    </div>*/}
                            {/*                )}*/}
                            {/*            </div>*/}
                            {/*        )}*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className='funding-methods-caracs-container background-color-gradient card'>
                                {fundingMethods[0][`caracs${formattedLanguage}`].map((carac, index) => (
                                    <div className='funding-methods-carac' key={index}>
                                        <img
                                            className='funding-methods-carac-image'
                                            src={fundingMethods[0].caracsPointerImage.url}
                                            alt='carac'
                                        />
                                        <div className='funding-methods-carac-text'>{carac}</div>
                                    </div>
                                ))}
                            </div>

                            <div className='funding-methods-image-container for-web'>
                                <img
                                    key={currentImageIndex}
                                    className='funding-methods-image'
                                    src={images[currentImageIndex]}
                                    alt='Funding Methods'
                                />
                            </div>

                            {/*<div className='funding-methods-payment-logos-container'>*/}
                            {/*    {fundingMethods[0].images.map((image, index) => (*/}
                            {/*        <div key={index} className='funding-methods-payment-logo'>*/}
                            {/*            <img*/}
                            {/*                className='funding-methods-payment-logo-image'*/}
                            {/*                src={`${MEDIA_BASE_URL}${image}`}*/}
                            {/*                alt='Payment Logo'*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    ))}*/}
                            {/*</div>*/}
                        </div>

                        <div className='funding-methods-steps-main-container'>
                            <ComponentTitle
                                titlePartOne={fundingMethods[0][`stepsTitlePartOne${formattedLanguage}`]}
                                titlePartTwo={fundingMethods[0][`stepsTitlePartTwo${formattedLanguage}`]}
                                titlePartOneColor='white'
                            />

                            <div className='funding-methods-steps-container'>
                                {fundingMethods[0].steps.map((step, index) =>
                                    <>
                                        <div className='funding-method-step-container' key={index}>
                                            <div className='funding-method-step-header-container'>
                                                <div className={`
                                                    funding-method-step-header
                                                    ${step.isPartiallyFilled === true && 'funding-method-step-header-container-partially-filled'}
                                                    ${step.isFilled === true && 'funding-method-step-header-container-filled'}
                                                `}>
                                                    <img
                                                        className='funding-method-step-header-icon'
                                                        src={`${MEDIA_BASE_URL}${step.imagePath}`}
                                                        alt='Step Icon'
                                                    />
                                                    <h2 className={`${step.isPartiallyFilled === true || step.isFilled === true ? 'funding-method-step-header-filled' : 'funding-methods-step-header'}`}>
                                                        {step[`header-${language}`]}
                                                    </h2>
                                                </div>

                                                {step.imageSeparator === 'separatorImageBottom' && (
                                                    <div className='for-web funding-method-step-header-separator-bottom'>
                                                        <img
                                                            className='funding-method-step-header-separator-image'
                                                            src={fundingMethods[0].separatorImageBottom.url}
                                                            alt='Separator'
                                                        />
                                                    </div>
                                                )}

                                                {step.imageSeparator === 'separatorImageTop' && (
                                                    <div className='for-web funding-method-step-header-separator-top'>
                                                        <img
                                                            className='funding-method-step-header-separator-image'
                                                            src={fundingMethods[0].separatorImageTop.url}
                                                            alt='Separator'
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            <div
                                                className={`funding-methods-step-title ${language === 'ar' && 'funding-methods-step-title-ar'} ${language === 'en' && 'funding-methods-step-title-en'}`}
                                            >
                                                {step[`title-${language}`]}
                                            </div>
                                            <div className='funding-methods-step-subtitle'>{step[`subtitle-${language}`]}</div>

                                            {step[`buttonTitle-${language}`] && (
                                                <div className='funding-methods-step-button'>
                                                    <CustomButton
                                                        title={step[`buttonTitle-${language}`]}
                                                        onClick={() => onOpenAccountHandler(step.buttonLink)}
                                                        hasNoGradient={true}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default FundingMethods;