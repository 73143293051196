import '../../../App.css';
import './Services.css';
import Service from "./service/Service";
import useVisibility from "../../../hooks/useVisibility";

function Services({service, language}) {
    const [isVisible, containerRef] = useVisibility();

    return(
        <>
            {service && (
                <div
                    style={{
                        backgroundImage: `url(${service.backgroundImage.url})`,
                    }}
                    className={`component-container accounts-services-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container'>
                        {service.leftBorderImage && (
                            <img
                                className='edge-image left-image for-web-lower'
                                src={`${service.leftBorderImage.url}`}
                                alt='Accounts Service'
                            />
                        )}

                        <div className='accounts-services'>
                            <h3 className='accounts-services-title'>{service[`title${language}`]}</h3>

                            <div className='accounts-services-data-container'>
                                {service[`services${language}`].map((s, index) => (
                                    <Service
                                        key={index}
                                        service={s}
                                        pointerImageUrl={`${service.pointerImage.url}`}
                                    />
                                ))}
                            </div>
                        </div>

                        {service.rightBorderImage && (
                            <img
                                className='edge-image right-image for-web-lower'
                                src={`${service.rightBorderImage.url}`}
                                alt='Accounts Service'
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default Services;