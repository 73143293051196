import '../../../../App.css';
import './Definition.css';
import useVisibility from "../../../../hooks/useVisibility";

function Definition({definition, language}) {
    const [isVisible, containerRef] = useVisibility();

    return (
        <>
            {definition && (
                <div
                    className={`who-we-are-definition-container component-container card box-shadow-all-sides ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
                    ref={containerRef}
                >
                    <div className='component-data-container'>
                        { definition.title && <h2>{definition.title}</h2> }

                        <div className='who-are-definition-content-container'>
                            {definition.definitionContent.map((content, index) => (
                                <div className='who-are-definition-content' key={index}>
                                    <h3 className='who-we-are-definition-color who-we-are-definition-padding'>{content[`title-${language}`]}</h3>
                                    <div className='body-one who-we-are-definition-padding'>{content[`description-${language}`]}</div>
                                    {index < definition.definitionContent.length - 1 && (
                                        <div className='who-we-are-definition-separator'></div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Definition;