import '../../../App.css';
import './Definition.css';
import CustomButton from "../../ui/CustomButton/CustomButton";
import {openExternalLink} from "../../../services/AuthService";
import useVisibility from "../../../hooks/useVisibility";

function Definition({definition, language}) {
    const [isVisible, containerRef] = useVisibility();

    function onButtonClickHandler() {
        openExternalLink(definition.buttonLink);
    }

    return (
        <div
            className={`component-container-no-full-height ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}
            ref={containerRef}
        >
            <div className='component-data-container markets-definition-container'>
                <h2 className='markets-definition-title'>{definition[`title-${language}`]}</h2>
                <h3 className='markets-definition-subtitle'>{definition[`subtitle-${language}`]}</h3>

                {/*<div className='markets-definition-separator'></div>*/}

                <div className='body-one markets-definition-description'>{definition[`description-${language}`]}</div>

                {definition[`buttonTitle-${language}`] && (
                    <div className='markets-definition-button-container'>
                        <CustomButton
                            title={definition[`buttonTitle-${language}`]}
                            onClick={() => onButtonClickHandler()}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Definition;