import '../../../App.css';
import './CustomInput.css';
import {MEDIA_BASE_URL} from "../../../constants/API";

function CustomInput({type, title, value, error, onChange, isDropdown, dropdownPlaceholder, dropdownOptions }) {
    return (
        <div>
            <div className='form-item-label subtitle'>{title}</div>

            {isDropdown && (
                <select
                    className='form-item-input form-item-input-select'
                    value={value}
                    onChange={(e) => onChange({value: e.target.value})}
                    style={{backgroundImage: `url(${MEDIA_BASE_URL}/uploads/dropdown_form_claret_da03374914.svg)`}}
                >
                    <option value="" disabled>{dropdownPlaceholder}</option>
                    {dropdownOptions.map((option) => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </select>
            )}

            {!isDropdown && (
                <input
                    className='form-item-input'
                    type={type}
                    value={value}
                    onChange={(e) => onChange({value: e.target.value})}
                />
            )}

            {error && (
                <div className='form-error-container'>
                    <div className='form-error-icon'>!</div>
                    <div>{error}</div>
                </div>
            )}
        </div>
    );
}

export default CustomInput;