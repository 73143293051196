import Header from "../../../components/ui/header/Header";
import JoinUs from "../../../components/about-us/careers/join-us/JoinUs";
import Jobs from "../../../components/about-us/careers/jobs/Jobs";
import Culture from "../../../components/about-us/careers/culture/Culture";
import {useEffect, useState} from "react";
import {
    getCareersCulture,
    getCareersHeader,
    getCareersJobs,
    getCareersJoinUs
} from "../../../services/about-us/CareersService";
import LoadingSpinner from "../../../components/ui/loading-spinner/LoadingSpinner";

function Careers({language}) {
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(true);

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        async function fetchData() {
            try {
                const [
                    header,
                    joinUs,
                    culture,
                    jobs
                ] = await Promise.all([
                    getCareersHeader(),
                    getCareersJoinUs(),
                    getCareersCulture(),
                    getCareersJobs()
                ]);

                const headerData = header.data.data[0];

                const joinUsData = joinUs.data.data[0];

                const cultureData = culture.data.data[0];

                const jobsData = jobs.data.data.map(job => ({
                    ...job,
                    jobOpenings: job.jobOpenings.filter(j => j.isVisible === true),
                }))[0];

                setContent({
                    header: headerData,
                    joinUs: joinUsData,
                    culture: cultureData,
                    jobs: jobsData
                });

                setLoading(false);
            } catch (error) {}
        }

        fetchData();
    }, []);

    if(loading === true) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <div>
            <Header
                title={content.header[`title${formattedLanguage}`]}
                subtitle={content.header[`subtitle${formattedLanguage}`]}
                sourcePath={`${content.header.image.url}`}
            />

            <JoinUs content={content.joinUs} language={language} />

            <Culture culture={content.culture} language={language} />

            <Jobs jobs={content.jobs} language={language} />
        </div>
    );
}

export default Careers;