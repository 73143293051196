import '../../../../App.css';
import './MissionVision.css';
import MissionVisionContent from "./mission-vision-content/MissionVisionContent";
import useVisibility from "../../../../hooks/useVisibility";
import {MEDIA_BASE_URL} from "../../../../constants/API";

function MissionVision({missionVision, language}) {
    const [isVisible, containerRef] = useVisibility();

    const formattedLanguage = language.toUpperCase();

    return (
        <>
            {/*{missionVision && (*/}
            {/*    <div*/}
            {/*        className={`mission-vision-container ${isVisible ? 'slide-up' : 'slide-up-hidden'}`}*/}
            {/*        ref={containerRef}*/}
            {/*    >*/}
            {/*        <div className='for-web-lower'>*/}
            {/*            <img src={`${missionVision.image.url}`} alt='Mission Vision'/>*/}
            {/*        </div>*/}
            {/*        <div className='mission-vision-image-data-container'>*/}
            {/*            <h2 className='mission-vision-title'>{missionVision.title}</h2>*/}

            {/*            <MissionVisionContent content={missionVision.content}/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}

            {/*NEW CONTENT */}
            {missionVision && (
                <div
                    style={{
                        backgroundImage: `url(${missionVision.backgroundImage.url})`,
                    }}
                    className='component-container mission-vision-new-container'
                >
                    <div className='component-data-container mission-vision-new-data-container'>
                        {missionVision.content.map((data, index) => (
                            <div className='mission-vision-content-new-container card' key={index}>
                                <div className='mission-vision-new-text-container'>
                                    <h3 className='mission-view-new-title'>{data[`title-${language}`]}</h3>
                                    <div className='mission-view-new-description'>{data[`description-${language}`]}</div>
                                </div>

                                <div className='mission-vision-new-image-container'>
                                    <img
                                        src={`${MEDIA_BASE_URL}${data.imagePath}`}
                                        alt={data[`title${formattedLanguage}`]}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

export default MissionVision;