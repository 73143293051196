import '../../../App.css';
import './FooterScreens.css';
import CustomButton from "../../ui/CustomButton/CustomButton";
import {useNavigate} from "react-router-dom";
import {openExternalLink} from "../../../services/AuthService";

function FooterScreens({screens, language}) {
    const navigate = useNavigate();

    const footerDataWithNoSubScreenButton =
        screens.filter(data => data.subScreens.some(subScreen => subScreen.button == null));

    const footerDataWithSubScreenButton =
        screens.filter(data => data.subScreens.some(subScreen => subScreen.button != null));

    function onSignupClicked(url) {
        openExternalLink(url);
    }

    function hasButton(data) {
        return data.subScreens.filter(s => s.button).length > 0
    }

    return (
        <div className='footer-data-screens'>
            {screens.map((data, index) => (
                <div className={`${!hasButton(data) && 'footer-data-screen-container'}`} key={index}>
                    <div className='for-web footer-data-screen'>
                        <h4>{data[`title-${language}`]}</h4>

                        {data.subScreens.map((subScreen, subScreenIndex) => (
                            <div className='footer-data-subscreens' key={subScreenIndex}>
                                {subScreen[`name-${language}`] &&
                                    <div
                                        className={`body-three ${subScreen.path && 'footer-data-subscreen'}`}
                                        onClick={() => navigate(subScreen.path)}
                                    >
                                        {subScreen[`name-${language}`]}
                                    </div>
                                }

                                {subScreen[`buttonTitle-${language}`] &&
                                    <div>
                                        <CustomButton
                                            title={subScreen[`buttonTitle-${language}`]}
                                            onClick={() => onSignupClicked(subScreen.buttonLink)}
                                            hasNoGradient={true}
                                        />
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            ))}

            {footerDataWithNoSubScreenButton.map((data, index) => (
                <div className='footer-data-screen-container' key={index}>
                    <div className='for-mobile footer-data-screen'>
                        <h4>{data[`title-${language}`]}</h4>

                        {data.subScreens.map((subScreen, subScreenIndex) => (
                            <div className='footer-data-subscreens' key={subScreenIndex}>
                                {subScreen[`name${language}`] &&
                                    <div
                                        className={`body-three ${subScreen.path && 'footer-data-subscreen'}`}
                                        onClick={() => navigate(subScreen.path)}
                                    >
                                        {subScreen[`name-${language}`]}
                                    </div>
                                }

                                {subScreen[`buttonTitle-${language}`] &&
                                    <div>
                                        <CustomButton
                                            title={subScreen[`buttonTitle-${language}`]}
                                            onClick={() => onSignupClicked(subScreen.buttonLink)}
                                            hasNoGradient={true}
                                        />
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            ))}

            {footerDataWithSubScreenButton.map((data, index) => (
                <div className='footer-data-screen-button-container for-mobile' key={index}>
                    <div className='footer-data-screen'>
                        {data.subScreens.map((subScreen, subScreenIndex) => (
                            <div key={subScreenIndex}>
                                <CustomButton
                                    title={subScreen[`buttonTitle-${language}`]}
                                    onClick={() => onSignupClicked(subScreen.buttonLink)}
                                    hasNoGradient={true}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default FooterScreens;