import './WhoWeAre.css';
import Definition from "../../../components/about-us/who-we-are/definition/Definition";
import MissionVision from "../../../components/about-us/who-we-are/mission-vision/MissionVision";
import Prizes from "../../../components/about-us/who-we-are/prizes/Prizes";
import Values from "../../../components/about-us/who-we-are/values/Values";
import Header from "../../../components/ui/header/Header";
import {useEffect, useState} from "react";
import {
    getWhoWeAreDefinition,
    getWhoWeAreHeader,
    getWhoWeAreMeetTeam, getWhoWeAreMissionVision, getWhoWeArePrizes, getWhoWeAreValues
} from "../../../services/about-us/WhoWeAreService";
import MeetTheTeam from "../../../components/about-us/who-we-are/meet-the-team/MeetTheTeam";
import LoadingSpinner from "../../../components/ui/loading-spinner/LoadingSpinner";

function WhoWeAre({language}) {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);

    const formattedLanguage = language.toUpperCase();

    useEffect(() => {
        async function fetchData() {
            try {
                const [
                    header,
                    definition,
                    meetTheTeam,
                    missionVision,
                    value,
                    prize
                ] = await Promise.all([
                    getWhoWeAreHeader(),
                    getWhoWeAreDefinition(),
                    getWhoWeAreMeetTeam(),
                    getWhoWeAreMissionVision(),
                    getWhoWeAreValues(),
                    getWhoWeArePrizes()
                ]);

                const headerData = header.data.data[0];

                const definitionData = definition.data.data.map(definition => ({
                    ...definition,
                    definitionContent: definition.definitionContent.filter(content => content.isVisible === true)
                }))[0];

                const meetTheTeamData = meetTheTeam.data.data.map(d => ({
                    ...d,
                    teamMembers: d.teamMembers.filter(member => member.isVisible === true)
                }))[0];

                const missionVisionData = missionVision.data.data[0];

                const valueData = value.data.data[0];

                const prizeData = prize.data.data.map(prize => ({
                    ...prize,
                    prizes: prize.prizes.filter(p => p.isVisible === true)
                }))[0];

                setContent({
                    header: headerData,
                    definition: definitionData,
                    meetTheTeam: meetTheTeamData,
                    missionVision: missionVisionData,
                    value: valueData,
                    prize: prizeData
                });

                setLoading(false);
            } catch (error) {}
        }

        fetchData();
    }, []);

    if(loading === true) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <div>
            <Header
                title={content.header[`title${formattedLanguage}`]}
                subtitle={content.header[`subtitle${formattedLanguage}`]}
                sourcePath={`${content.header.image.url}`}
            />

            <Definition definition={content.definition} language={language} />

            {/*<MeetTheTeam content={content.meetTheTeam} />*/}

            <MissionVision missionVision={content.missionVision} language={language} />

            <Values value={content.value} language={language} />

            <Prizes prize={content.prize} language={language} />
        </div>
    );
}

export default WhoWeAre;