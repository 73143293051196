import '../../../App.css';
import './TrustPilot.css';
import {useEffect} from "react";

function TrustPilot() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div
            className="trustpilot-widget trust-pilot-container"
            data-locale="en-GB"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="66f936bbf3776e76986a9d05"
            data-style-height="150px"
            data-style-width="100%"
        >
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a href="https://uk.trustpilot.com/review/givtrade.com" target="_blank" rel="noopener noreferrer" />
        </div>
    );
}

export default TrustPilot;