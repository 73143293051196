import '../../../../App.css';
import './AffiliateProgramIntroService.css';
import {MEDIA_BASE_URL} from "../../../../constants/API";

function AffiliateProgramIntroService({service, language}) {
    return (
        <div className='affiliate-program-intro-service-container'>
            <div className='affiliate-program-intro-service-title-container'>
                <div className='affiliate-program-intro-service-image-container'>
                    <img src={`${MEDIA_BASE_URL}${service.imagePath}`} alt='Service'/>
                </div>

                <div className='affiliate-program-intro-service-title'>
                    {service[`title-${language}`]}
                </div>
            </div>

            <div className='affiliate-program-intro-service-description subtitle'>
                {service[`description-${language}`]}
            </div>
        </div>
    );
}

export default AffiliateProgramIntroService;