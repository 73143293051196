import '../../../../App.css';
import '../our-app-source-style.css';
import {MEDIA_BASE_URL} from "../../../../constants/API";
import {openExternalLink} from "../../../../services/AuthService";

function OurAppMobileSource({source}) {
    function onSourceClickHandler(url) {
        openExternalLink(url);
    }

    return (
        <div className='our-app-source-source background-color-gradient card'>
            <h3>{source.title}</h3>
            {source.sources.map((source, index) => (
                <div
                    key={index}
                    className='our-app-source-source-app'
                    onClick={() => onSourceClickHandler(source.downloadLink)}
                >
                    <img
                        className='our-app-source-source-logo'
                        src={`${MEDIA_BASE_URL}${source.imagePath}`}
                        alt='Source Logo'
                    />
                </div>
            ))}
        </div>
    );
}

export default OurAppMobileSource;